.accordian {
  width: 100%;
  margin: auto;
}
.accordian li {
  margin-top: 30px;
}

.openItem {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: #161616;
}
.accordian h3 {
  color: #fff;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 29.4px */
}
.accordian pre {
  color: rgba(153, 153, 153, 0.6);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 23.8px */
  padding: 30px 0;
}
.accordian li button {
  background: #2e1d89;
  backdrop-filter: blur(50.5px);
  padding: 10px;
  border-radius: 100%;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .accordian h3 {
    font-size: 16px;
  }
  .accordian pre {
    padding: 30px 0;
  }
}
