@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");

.homepage h2 {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  letter-spacing: -0.96px;
  text-align: left;
  margin: 0;
  padding: 0;
}
.homepage h5 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.52px;
}
.homepage h3 {
  opacity: 1;
}
.landing {
  background: #593fd9;
  height: 90dvh;
  position: relative;
  overflow: hidden;
  transition: height 300ms ease-in-out;
}
.landing .background {
  border-radius: 0px 0px 283px 283px;
  background: linear-gradient(180deg, #2e1d89 0%, rgba(46, 29, 137, 0.1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 1;
}
.landing h1 {
  color: #fff;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: normal;
  text-transform: none;
  transition: all 400ms ease-in-out;
  animation: 400ms linear 500ms zoom-in;
}
.landing h3 {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.48px;
}
.landing button {
  border-radius: 8px;
  background: #ff7043;
  padding: 12px 32px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.landing p {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.36px;
}
.landingCard {
  width: 30%;
  aspect-ratio: 4 / 5;
  border-radius: 24px;
  background: #f1f6fc;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
  transition: all 400ms ease-in-out;
  cursor: pointer;
  animation: 400ms linear 500ms pop-up;
}
@keyframes pop-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes zoom-in {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.landingCard:hover {
  transform: translate(2px);
  transform: scale(0.8);
}
.card {
  border-radius: 24px;
  background: #bfcaff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
}
.card h3 {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 26.04px */
  letter-spacing: -0.42px;
}
.card p {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}
.card img {
  width: 100%;
  /* mix-blend-mode: darken; */
}
.perks {
  background: #f2e9ff;
}
.perks h4 {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 34.72px */
  letter-spacing: -1.12px;
  width: 50%;
}
.perks span {
  color: #fff;
  background: #5a3fd9;
  padding: 0 4px;
}

.howItWorks h4 {
  color: #121212;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 29.4px */
  letter-spacing: -0.42px;
}
.howItWorks p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}
.dot {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #4f52e6;
  margin-top: 10px;
}
.line {
  background: #4f52e6;
  width: 4px;
  border-radius: 16px;
  height: 100%;
  margin: -5px auto 0;
}
.insight {
  background: #f2e9ff;
}
.insight h3 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 61.6px */
  letter-spacing: -0.88px;
}
.insight p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}
.testimonial {
  background: #f9f9f9;
}
.testimonial h2 {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 56px */
  letter-spacing: -2px;
  text-transform: capitalize;
}
.testimonialCard {
  padding: 16px;
  max-width: 320px;
  width: 100%;
  background: #fff;
  border-radius: 16px;
  flex-shrink: 0;
}
.testimonialCard img {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 52px;
  object-fit: cover;
}
.testimonialCard pre {
  color: rgba(0, 0, 0, 0.8);
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.testimonialName {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.testimonialRole {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.qna {
  background: #121212;
  padding: 40px 0;
}
.qna h2 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -1.28px;
}
.help {
  background: #161616;
  padding: 40px 0;
}
.help p {
  color: #fdfdfd;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 29.4px */
}
.help img {
  width: 44px;
  height: 44px;
}
.mailIcon {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50.5px);
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 100%;
}
@media (max-width: 768px) {
  .homepage h2 {
    font-size: 32px;
  }
  .homepage h5 {
    font-size: 16px;
  }
  .landing {
    height: 98dvh;
  }
  .landing h1 {
    font-size: 40px;
    text-align: left;
  }
  .landing h3 {
    font-size: 13px;
    text-align: left;
    width: 100%;
  }
  .landing button {
    width: 100%;
  }
  .landingCard {
    width: 20%;
    margin-top: 0;
    border-radius: 12px;
  }
  .card {
    width: 90%;
  }
  .perks h4 {
    width: 100%;
  }
  .perks p {
    width: 100%;
    text-align: left;
  }
  .insight h3 {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
  .insight p {
    font-size: 16px;
  }
  .testimonialCard {
    max-width: 80%;
  }
  .qna {
    padding: 30px 0;
  }
  .qna h2 {
    font-size: 24px;
  }
  .help p {
    font-size: 16px;
  }
  .help img {
    width: 32px;
    height: 32px;
  }
  .mailIcon {
    width: 32px;
    height: 32px;
    padding: 7px;
  }
}
