.theSliderGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding: 0 24px;
}

.theSliderEach {
  width: 100%;
  height: auto;
  position: relative;
}

.quotes {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  text-align: center;
  color: #FFFFFF;
}

.theSliderEachTemplate {
  padding: 24px;
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eachImg {
  width: 100%;
  /* aspect-ratio: 1/1; */
  height: 30.556vw;
  object-fit: cover;
  object-position: top;
}

.campaign {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  margin-bottom: 4px;
  margin-top: 11px;
  text-transform: uppercase;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.decript {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.glass {
  position: absolute;
  width: 302px;
  height: 86px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 0 0 20px 20px;
  bottom: 0px;
  padding: 12px 16px;
}

.flexmm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.imgCircle {
  width: 58px;
  height: 58px;
  border-radius: 50px;
  object-fit: cover;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ordn" on;
  color: #ffffff;
  margin-bottom: 3px;
}

.location {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  font-feature-settings: "ordn" on;
  color: #ffffff;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
}

@media only screen and (max-width: 880px) {
  .eachImg {
    aspect-ratio: 1/1; 
    height: auto;
  }
  .theSliderGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
    padding: 0 16px;
  }
  .imgfull,
  .oneGrid {
    width: 175px;
    height: 241px;
    border-radius: 10px;
  }

  .flexmm {
    gap: 8px;
  }

  .glass {
    width: 175px;
    padding: 8px;
    height: 46px;
  }

  .imgCircle {
    width: 32px;
    height: 32px;
  }

  .name {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  .play {
    width: 20px;
  }
}