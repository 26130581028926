.background {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.orglogo {
  /* width: 40%; */
  position: absolute;
  top: 24px;
  left: 23px;
  height: 50px;
  object-fit: contain;
}
.thegrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
}
.firstImg {
  height: 52%;
}
.oneGrid {
  width: 100%;
  height: 328px;
  position: relative;
  cursor: pointer;
  border-radius: 15px;
}
.imgiNgRID {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 63%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.gridHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 10px;
}
.absoluite {
  /* position: absolute; */
  bottom: 0px;
  border-radius: 0 0 15px 15px;
  height: 48%;

  /* background: linear-gradient(180deg, #1D120E 0%, #5A2D10 100%); */
}
.buttun {
  width: 67px;
  height: 22px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 4px 100px rgb(0 0 0 / 30%);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: #014746;
}
.gridPara {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Inter";
  font-style: normal;
  max-width: 478px;
  /* font-weight: 400; */
  font-size: 13px;
  line-height: 140%;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.campaignHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 30px;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
}
.coverHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  /* line-height: 73px; */
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  margin-bottom: 5px;
}
.flexico {
  padding-top: 120px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.covertext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  max-width: 670px;
  margin-bottom: 50px;
}
.coverAgre {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  /* line-height: 73px; */
  text-align: center;
  letter-spacing: -0.05em;
  background: linear-gradient(90.66deg, #0077ab 55.04%, #66019d 92.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media screen and (max-width: 480px) {
  .buttun {
    width: max-content;
    height: 44px;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0px 4px 100px rgb(0 0 0 / 30%);
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px;
  }
  .buttun > p {
    font-size: 16px;
  }
  .firstImg {
    height: 50%;
  }
  .coverHead,
  .coverAgre {
    font-size: 24px;
    line-height: 29px;
  }
  .covertext {
    font-size: 17px;
    line-height: 140%;
    margin-bottom: 40px;
    padding-left: 17px;
    padding-right: 17px;
  }
  .campaignHead {
    font-size: 20px;
    line-height: 140%;
    padding-left: 17px;
  }
  .thegrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .gridHead {
    font-size: 28px;
    line-height: 135%;
  }
  .gridPara {
    font-size: 17px;
    line-height: 24px;
  }
  .absoluite {
    padding: 17px;
    height: 50%;
  }
  .flexico {
    padding-top: 93px;
  }
  .orglogo {
    width: 30%;
    position: absolute;
    top: 24px;
    left: 23px;
    height: 50px;
  }
  .oneGrid {
    width: 100%;
    height: 515px;
    position: relative;
    cursor: pointer;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .firstImg {
    height: 50%;
  }
  .coverHead,
  .coverAgre {
    font-size: 24px;
    line-height: 29px;
  }
  .orglogo {
    /* width: 30%; */
    position: absolute;
    top: 24px;
    left: 23px;
    height: 50px;
  }
  .covertext {
    font-size: 17px;
    line-height: 140%;
    margin-bottom: 40px;
    padding-left: 17px;
    padding-right: 17px;
  }
  .campaignHead {
    font-size: 20px;
    line-height: 140%;
    padding-left: 17px;
    padding-right: 0;
  }
  .thegrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .gridHead {
    font-size: 14px;
    line-height: 135%;
  }
  .gridPara {
    font-size: 13px;
    line-height: 19px;
  }
  .absoluite {
    padding: 17px;
    height: 50%;
  }
  .flexico {
    padding-top: 93px;
  }
}
@media screen and (min-device-width: 781px) and (max-device-width: 1350px) {
  .thegrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
