.titleD {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 111px;
  letter-spacing: -0.04em;
  color: #000000;
  text-align: center;
}

.trendingSubtitle {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #111111;
  text-align: center;
  margin-top: 11px;
}

.theSliderGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding: 0 24px;
}

.theSliderEach {
  width: 100%;
 height: auto;
}

.theSliderEach>img {
  width: 100%;
  height:30.556vw;
  object-fit: cover;
  object-position: top;
}

.oneGrid {
  width: 335px;
  height: 402px;
  border-radius: 20px;
}

.oneGrid :first-child {
  padding-left: 0px !important;
}

.imgfull {
  width: 315px;
  height: 402px;
  object-fit: cover;
  position: relative;
  border-radius: 20px;
  object-position: top;
}

.campaign {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: #000000;
  margin-bottom: 4px;
  margin-top: 11px;
  text-transform: uppercase;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.decript {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.glass {
  position: absolute;
  width: 315px;
  min-height: 153px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 0 0 20px 20px;
  bottom: 0px;
  padding: 18px 14px 30px 16px;
}

@media only screen and (max-width: 880px) {
  .theSliderEach>img {
    aspect-ratio: 1/1; 
    height: auto;
  }
  .theSliderGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
   
  }

  .theSliderEach {
    height: auto;
  }
  .imgfull,
  .oneGrid {
    width: 175px;
    height: 241px;
    border-radius: 10px;
  }

  .glass {
    width: 175px;
    min-height: 102px;
    max-height: 102px;
    padding: 8px;
    border-radius: 0 0 10px 10px;
  }

  .campaign {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 6px;
  }

  .decript {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: -0.04em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .titleD {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.03em;
  }
}