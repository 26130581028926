.theSliderGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding: 0 24px;
}

.theSliderEach {
  width: 100%;
  height: auto;
}

.quotes {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}

.theSliderEachTemplate {
  padding: 24px;
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theSliderEach > img {
  width: 100%;
  /* aspect-ratio: 1/1; */
  height: 30.556vw;
  object-fit: cover;
  object-position: top;
}

.campaign {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: #000000;
  margin-bottom: 4px;
  margin-top: 11px;
  text-transform: uppercase;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.decript {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleShow {
  width: 427px;
  height: 540px;
  position: relative;
  background: linear-gradient(
    179.96deg,
    rgba(0, 0, 0, 0) 73.36%,
    #000000 99.96%
  );
  border-radius: 20px;
}

.showIMg {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.absol {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  padding: 20px;
}

.contest {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 4px;
}

.contestNmae {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  color: #ffffff;
}

@media only screen and (max-width: 880px) {
  .theSliderEach > img {
    aspect-ratio: 1/1;
    height: auto;
  }

  .theSliderEach {
    width: 100%;
    min-height: 440px;
  }
  .theSliderGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
    padding: 0 16px;
  }
  .singleShow {
    width: 175px;
    height: 241px;
    border-radius: 10px;
  }

  .showIMg {
    border-radius: 10px;
  }

  .absol {
    padding: 8px;
    height: auto;
  }

  .contest {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 4px;
  }

  .contestNmae {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
  }
}
