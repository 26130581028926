.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}
.container {
  width: 100%;
  height: 100vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container h2 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
  position: sticky;
  top: 0;
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  /* height: 82%; */
  height: 100%;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  height: 94px;
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper button {
  padding: 12px 32px;
  border-radius: 8px;
  background: #161616;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
