.helpPopup {
  position: absolute;
  z-index: 50;
  top: 15px;
  left: 5px;
  width: 50vb;
  max-width: 500px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  background: #ffffff;
  border-radius: 0 8px 8px 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-border-radius: 0 8px 8px 8px;
  -moz-border-radius: 0 8px 8px 8px;
  -ms-border-radius: 0 8px 8px 8px;
  -o-border-radius: 0 8px 8px 8px;
}

.popupContent {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  color: #000000;
}

.helpIconArrow {
  position: absolute;
  top: -10px;
  left: 0;
}
.closeButton {
  display: none;
}

.learnMore {
  color: #324fee;
}

@media (max-width: 620px) {
  .helpPopup {
    max-width: 234px;
    left: 50%;
    transform: translateX(-50%);
  }
  .helpIconArrow {
    display: none;
  }
  .closeButton {
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
