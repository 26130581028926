.bgMe {
  background: var(--third-color);
}
.theBox {
  width: 100%;
  height: auto;
  padding: 30px 10px 10px 10px;
  background: var(--third-color);
  backdrop-filter: blur(37px);
  border-radius: 10px;
  position: relative;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #120a08;
  max-width: 208px;
}
.viewBox {
  width: 100%;
  margin-top: 30px;
  height: 80px;
  background: radial-gradient(
    100% 2376.56% at 0% 5.63%,
    #985fbb 0%,
    #d6c75b 26.04%,
    #52aa56 50.52%,
    #3cd5db 74.48%,
    #51d9a2 100%
  );
  border-radius: 10px;
  padding: 2px;
  position: inherit;
  z-index: 1111;
}
.InsideBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  gap: 36px;
  background: #212025;
  padding: 23px 16px;
}
.exportxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: white;
}
.blueEgg {
  display: none;
}
.grids {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  position: inherit;
  z-index: 1111;
}
.singlePoster {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin-top: 30px;
}
.campName,
.viewContestText {
  display: none;
}
@media only screen and (min-width: 865px) {
  .grids {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    position: inherit;
    z-index: 1111;
  }
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    max-width: 574px;
  }
  .singlePoster {
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 358px;
    margin-top: 30px;
  }
  .theBox {
    width: 100%;
    height: auto;
    padding: 30px 10px 10px 10px;
    background: transparent;
    backdrop-filter: blur(37px);
    border-radius: 10px;
    position: relative;
  }
  .viewBox,
  .blueEgg {
    display: none;
  }
  .viewContestText {
    font-family: "Inter";
    font-style: normal;
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    color: #1455d2;
    display: block;
    cursor: pointer;
  }
  .campName {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.04em;
    color: #120a08;
    margin-top: 16px;
    display: block;
  }
}
