.carousel {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  /* max-width: 600px; */
  /* margin: auto; */
  overflow: hidden;
  border: 2px solid #ddd; /* Optional: border for better visibility */
  border-radius: 10px; /* Optional: rounded corners */
}

.carouselInner {
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselImage {
  width: 100%;
  height: 100%;
  display: block; /* Ensure image displays as a block */
  object-fit: contain;
}

.carouselArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  opacity: 50%;
  font-size: 12px; /* Increase arrow size for better visibility */
  line-height: 1;
  border-radius: 5px;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.carouselDots {
  text-align: center;
  padding: 5px 0;
  position: relative;
}

.dot {
  display: inline-block;
  width: 8px; /* Slightly larger for better visibility */
  height: 8px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for dot color */
}

.dot.active {
  background-color: #717171;
}
