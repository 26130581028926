.landing {
  background: #f2f0eb;
  text-align: left;
  min-height: 70vh;
  height: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.landing h1 {
  color: #fff;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-align: left;
  letter-spacing: normal;
  text-transform: none;
}
.landing p {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
  opacity: 0.6;
}
.card {
  border-radius: 16px;
  box-shadow: 0px 4px 32px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
.card img {
  width: 100%;
  height: 100%;
}
.comingSoon {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  padding: 14px;
  align-items: end;
}
.comingSoon p {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 24.12px */
}
.footerBanner {
  background: #ccedff;
  width: 100%;
}
.footerBanner h1 {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-transform: none;
  text-align: left;
  color: #4e04c6;
  letter-spacing: normal;
}
.footerBanner p {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 24.12px */
}
@media (max-width: 768px) {
  .landing {
    background-position: center bottom;
    background-size: contain;
  }
  .footerBanner h1 {
    font-size: 28px;
  }
}
