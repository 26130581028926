.heading1 {
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.desciption1 {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 20px;
}
.gridItems {
  width: 100%;
  height: 100%;
}
.gridItems:nth-child(odd) {
  padding-right: 20px;
  border-right: 1px solid rgba(177, 177, 177, 0.6);
}
.gridItems:nth-child(even) {
  padding-left: 20px;
}
.campaignCard {
    min-height: 148px;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
}
.plusOrMinusButton {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 32px;
  border: 1px solid #e8e8e8;
  background: rgba(244, 243, 243, 0.9);
  backdrop-filter: blur(12px);
  transition: background-color 0.2s ease-in;
}
.plusOrMinusButton:hover {
  cursor: pointer;
  background: rgba(192, 186, 186, 0.9);
}

@media (max-width: 768px) {
  .heading1 {
    font-size: 21px;
    font-family: var(--font-secondary);
    font-weight: 600;
    letter-spacing: 0.64px;
  }
  .desciption1 {
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: 500;
    line-height: 24px;
  }
  .gridItems {
    padding: 0;
  }
  .gridItems:not(:first-child) {
    padding-top: 20px;
    border-top: 1px solid rgba(177, 177, 177, 0.6);
  }
  .gridItems:not(:last-child) {
    padding-bottom: 20px;
  }
  .gridItems:nth-child(odd) {
    padding-right: 0;
    border-right: none
  }
  .gridItems:nth-child(even) {
    padding-left: 0;
  }
  .campaignCard {
    height: auto;
  }
  .plusOrMinusButton {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 24px;
  }
}
