.matchpageheading {
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  letter-spacing: -0.03em;
}

.matchpagedesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.03em;
}

.matchcardname {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.matchcardday {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
}

.matchbetween {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
}

.bottomcardheading {
  font-style: normal;
 
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */
  
 
}

.bottomcarddesc {
  font-family: "Inter";
 
  font-size: 16px;
  line-height: 140%;
  color: #171717;
}

.cardorgdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.04em;

  color: #4d4d4d;
}

.cardname {
  font-style: normal;
  
  font-size: 25px;
  line-height: 140%;
  /* or 35px */


  color: #171717;
}

.formheadingone {
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */
}

.formheadingtwo {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
}

.forminput {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.formdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.formdesctwo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.bottonfont {
  font-size: 16px;
}

.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.progresstext {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.03em;
}

.priceheading {
  font-weight: 600;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */

  letter-spacing: -0.03em;
}

.pricequote {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
.pricequotetwo {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
}

@media (max-width: 1100px) and (min-width: 768px) {
  .bottonfont {
    font-size: 14px;
  }
}

@media (max-width: 620px) {
  .formheadingone {
    font-size: 21px;
    line-height: 140%;
    /* or 29px */
  }

  .formheadingtwo {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 580px) {
  .matchcardday {
    font-weight: 600;
    font-size: 21px;
    line-height: 140%;
  }

  .matchbetween {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    /* or 39px */

    letter-spacing: -0.03em;
  }

  .extratext {
    display: none;
  }

  .bottonfont {
    font-size: 12px;
  }

  .progresstext {
    display: none;
  }
}
