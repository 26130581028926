.backgroundcntr {
  background-color: white;
  max-width: 1280px;
  padding: 50px 0px 115px 0px;
  position: relative;
  color: #171717;
  margin-left: auto;
  margin-right: auto;
}

.backgroundcntrddd {
  background-color: white;
  padding-top: 75px;
  /* padding-left: 100px; */
  max-width: 1280px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0px 0px 0px;
  position: relative;
  color: #171717;
}
.margintopp {
  margin-top: 20px;
}
.displist {
  width: 338px;
  margin-left: auto;
  margin-top: 46px;
  height: 56px;
  left: calc(50% - 361px / 2 + 176.5px);
  top: calc(50% - 56px / 2 + 1487.5px);
  background: #3080f8;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttonLoading {
  width: 338px;
  margin-left: auto;
  margin-top: 46px;
  height: 56px;
  left: calc(50% - 361px / 2 + 176.5px);
  top: calc(50% - 56px / 2 + 1487.5px);
  background: #2a5595;
  color: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.displist > p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.rulestoparti {
  /* display: flex; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #787878;
}
.rulestoparti > p {
  font-family: "Inter", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #787878;
  /* width: 731px; */
}
.formdiv {
  width: 100%;
  background: #f7f7f7;
  border-radius: 30px;
  /* height: 1420px; */
  display: flex;
  justify-content: center;
  padding: 50px 189px;
}
.heads {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.04em;
  color: #171717;
  text-align: center;
}
.formmi {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
}
.inputs {
  width: 468px;
  height: 56px;
  left: calc(50% - 468px / 2 + 123px);
  top: calc(50% - 56px / 2 + 511.5px);
  background: #ffffff;
  border: 2px solid rgba(20, 0, 102, 0.2);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.inputs::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #414141;
  opacity: 0.6;
}

.prize {
  width: 203px;
  height: 56px;
  left: calc(50% - 203px / 2 + 255.5px);
  top: calc(50% - 56px / 2 + 1303.5px);
  background: #ffffff;
  border: 2px solid rgba(20, 0, 102, 0.2);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prize > p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #171717;
}
.entryfree {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
}
.flexxx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 81px;
}
.posting {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.64);
}

.postingDetails {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #787878;
  margin: 20px 0;
}
.formikk {
  margin-top: 60px;
}
.applic {
  display: inline;
  color: red;
}
.applicx {
  display: inline;
  color: #5796f4;
  text-transform: capitalize;
}
.formonly {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  margin-bottom: 40px;
}
.formonly span {
  min-width: 171px;
}
.formonly input:focus {
  outline: 1px solid #171717;
}
.formonly select:focus {
  outline: 1px solid #171717;
}

.formonly option {
  width: 468px;
  height: 56px;
  background: #ffffff;
  border: 2px solid rgba(20, 0, 102, 0.2);
}
.link {
  color: #2a5595;
}
.Please {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-left: auto;
  margin-right: auto;
  width: 632px;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  color: #171717;
}
.Entry {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #171717;
}
.voucher {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.04em;
  color: #171717;
  margin-bottom: 25px;
}
.voucherRules {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #787878;
}
.list {
  list-style: decimal;
}
.container {
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (max-width: 600px) {
  .voucherRules {
    font-size: 17px;
    /* width: 341px; */
  }
  .voucher {
    font-size: 24px;
    margin-bottom: 36px;
    width: 341px;
  }
  .backgroundcntr {
    padding: 0px 0px 86px 0px;
  }
  .backgroundcntrddd {
    padding: 40px 16px 40px 16px;
  }
  .formonly {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .inputs {
    width: 338px;
  }
  

  .postingDetails {
    width: 334px;
    margin-right: auto;
    margin-left: auto;
  }
  .rulestoparti > p {
    width: 334px;
    margin-right: auto;
    margin-left: auto;
  }
  .heads {
    font-size: 24px;
    width: 343px;
    margin-left: auto;
    margin-right: auto;
  }
  .Entry {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    width: 343px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .Please {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    width: 341px;
  }
  .formdiv {
    padding: 16px 10px;
  }
  .entryfree {
    font-size: 12px;
  }
  .formikk {
    margin-top: 40px;
  }
  .prize {
    width: 145px;
    height: 44px;
    left: calc(50% - 145px / 2 + 96.5px);
    top: calc(50% - 44px / 2 + 310.5px);
    background: #ffffff;
    border: 2px solid rgba(20, 0, 102, 0.2);
    border-radius: 12px;
  }
  .displist {
    margin-right: auto;
  }
  .flexxx {
    justify-content: center;
    gap: 26px;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .formonly span {
    align-self: flex-start;
  }

  .formmi {
    font-size: 12px;
  }
}
