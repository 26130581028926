.backgroundcntr {
  background-color: #171717;
  width: 100%;
  padding: 123px 100px 115px 100px;
  position: relative;
}
.cover {
  max-width: 1280px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background-size: auto 125%;
  background-repeat: no-repeat;
  background-position: right center;
}
/* .coverTextParent{

} */
.coverImageParent {
  width: 443.86px;
  height: auto;
}
.imapgeabsolute {
  object-fit: cover;
  /* width: 443.86px; */
  right: 0px;
  top: 64px;
  object-fit: cover;
}
/* .imgdarking {
  position: absolute;
  height: 562.1240110698433px;
  width: 568.82911px;
  right: 0;
  border-radius: 0px;
  top: 64px;
  background: linear-gradient(89.17deg, #171717 3.77%, rgba(23, 23, 23, 0) 99.35%);
  transform: matrix(0.01, -1, 1, 0.01, 0, 0);
} */
.imgdarkingow {
  position: absolute;
  width: 336.43px;
  height: 380px;
  right: 123.6px;
  top: 62px;
  background: linear-gradient(
    89.17deg,
    #171717 3.77%,
    rgba(23, 23, 23, 0) 99.35%
  );
}
.linkss {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: #848484;
}
.heads {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.cotnesttdetals {
  margin-top: 75px;
  /* padding-left: 100px; */
  max-width: 1280px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.wheniscon {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  /* width: 302px; */
  letter-spacing: -0.04em;
  color: #eaeaea;
  margin-bottom: 10px;
}
.contestregarding {
  margin-bottom: 40px;
}
.dateofoc {
  /* width: 299px; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: #787878;
}
.heads > p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #faf863;
  width: 902px;
}
.detailsParent {
  display: flex;
  flex-wrap: wrap;
  gap: 0 325px;
}
.detslsdes {
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  max-width: 661px;
  color: #b8b8b8;
}
.nametitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  margin-top: 10px;
  letter-spacing: -0.04em;
  color: #fefb0a;
}
.prest {
  display: inline;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.cntestype {
  font-family: "Inter", sans-serif;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 120%;
  margin-top: 10px;

  letter-spacing: -0.04em;

  color: #ffffff;
}
@media only screen and (max-width: 800px) {
  .cover{
    background-image: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .imgdarking,
  .imapgeabsolute,
  .imgdarkingow {
    display: none;
  }
  .backgroundcntr {
    padding: 105px 16px 40px 16px;
  }
  .nametitle {
    font-size: 24px;
  }
  .detslsdes {
    font-size: 17px;
    /* width: 348px; */
  }
  .heads {
    margin-top: 47px;
  }
  .heads > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 348px;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.04em;
  }
  .cotnesttdetals {
    margin-top: 25px;
    padding-left: 16px;
  }
  .wheniscon {
    font-size: 24px;
  }
  .cntestype,
  .dateofoc {
    font-size: 17px;
  }
  .contestregarding {
    margin-bottom: 30px;
  }
  .linkss {
    font-size: 17px;
  }
  .prest {
    font-size: 17px;
  }
}
