.collabTxt {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  max-width: 559px;
}

.cardOfinf {
  background: #ffffff;
  box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.09);
  border-radius: 2px 2px 0px 0px;
}
.talkToUs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  width: max-content;
  height: 35px;
  background: #000000;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.fliterChip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  width: max-content;
  background: rgba(3, 91, 222, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
.chipInCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 6px;
  gap: 10px;
  width: max-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #025bde;
  height: 20px;
  background: #c0daff;
  border-radius: 2px;
}
.JoinasSponser {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  width: 123px;
  height: 35px;
  background: #0057d7;
  border-radius: 4px;
}
.divider {
  background: rgba(88, 140, 216, 0.3);
  border-radius: 3px;
  margin-left: 16px;
  width: 2px;
  height: 30px;
}
.collabrator {
  /* display: flex; */
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 10px;
  /* position: absolute; */
  width: max-content;
  /* height: 29px; */
  /* bottom: 10px; */
  cursor: pointer;
  right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background: #035bde;
  border-radius: 4px;
}
.collabrator:disabled {
  opacity: 20%;
}
* {
  box-sizing: border-box;
}

.card {
  position: absolute;
  background-color: #f8f8f8;
  border-radius: 1.5em;
  min-width: 400px;
  max-width: 1170px;
  width: 30%;
  padding: 2.6em 3.8em;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 40%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1), 0 32px 32px rgba(0, 0, 0, 0.15),
    0 64px 64px rgba(0, 0, 0, 0.15);
}
.note::after {
  content: "";
  width: 20%;
  height: 2px;
  border-radius: 999px;
  background-color: #afafaf;
  background: linear-gradient(
    135deg,
    rgba(43, 171, 217, 1) 0%,
    rgba(0, 130, 200, 1) 100%
  );
  position: absolute;
  top: -1.8em;
  left: 0;
}
.note {
  font-size: 0.8em;
  color: #8a8a8a;
  position: relative;
  margin-top: 4em;
}
.videoPlayer {
  height: 249px !important;
  margin-top: 23px;
}
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 8s infinite;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

@media only screen and (max-width: 750px) {
  .videoPlayer {
    height: 158px !important;
    margin-top: 15px;
  }
  .collabTxt {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #000000;
    max-width: max-content;
  }
  .collabrator {
    position: static;
  }
  .divider {
    background: rgba(88, 140, 216, 0.3);
    border-radius: 3px;
    margin-left: 8px;
    width: 2px;
    height: 30px;
  }
}
