.background {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
  border-top: 1px solid #d9d9d9;
}
.absoliieee{
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.insideColurc{
  font-size: 10px;
  color: #d9d9d9;
}
.insidePart{
  font-size: 13px;
  color: #d9d9d9;

}
.WithLink{
  font-size: 13px;
  color: #d9d9d9;
  text-decoration: underline;
  cursor: pointer;

}
.title {
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #000000;
  /* padding-left: 100px; */
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.imgUrl {
  object-fit: cover;
  width: 50vw;
}

.playbtnShow {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
}

.coloumncontroller {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px;
  /* column-count: 3;
    column-width: 33%;
    column-gap: 1px;
    padding-bottom: 99px; */
}

.thumbnailUrl {
  height: 100%;
  width: 50vw;
  border-radius: 0px;
  object-fit: cover;
  object-position: top;
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 20px;
    line-height: 140%;
    padding-left: 17px;
  }
  .coloumncontroller {
    grid-template-columns: 1fr 1fr;
  }
  .thumbnailUrl {
    height: 100%;
    width: 50vw;
    border-radius: 0px;
    object-fit: cover;
    object-position: top;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .title {
    font-size: 20px;
    line-height: 140%;
    padding-left: 17px;
  }
  .coloumncontroller {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .thumbnailUrl {
    height: 100%;
    width: 50vw;
    border-radius: 0px;
    object-fit: cover;
    object-position: top;
  }
}