.topBanner {
    width: 100%;
    object-fit: cover;
    height: 718px;
    margin-left: auto;
    margin-right: auto;
}

.bannerTxt {
    max-width: 1256px;
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 880px) {
    .topBanner {
        width: 100%;
        object-fit: cover;
        height: 450px;
        margin-left: auto;
        margin-right: auto;
    }
    .bannerTxt{
        width: 100%;
        font-size: 22px;
        line-height: 25px;
        padding: 16px;
        transform: translate(-50%, -35%);
    }
}