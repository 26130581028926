.titleD {
  font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 82px;
    line-height: 111px;
    letter-spacing: -0.04em;
    color: #000000;
    text-align: center;
    text-transform: uppercase;  
  }
  
  .trendingSubtitle {
    font-family: "Cochin";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    text-align: center;
    margin-top: 11px;
  }