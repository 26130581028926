.backgroundME {
  width: 100%;
  background: var(--secondary-color);
  height: 550px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.profileImg {
  margin-left: auto;
  width: 100%;
  height: 531px;
  object-fit: cover;
  object-position: top;
}
.absol {
  position: absolute;
  left: 26px;
  top: 399px;
}
.name {
  max-width: 338px;
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
}
.details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 2px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
@media only screen and (min-width: 865px) {
  .backgroundME {
    width: 100%;
    padding-left: 100px;
    background: var(--secondary-color);
    height: auto;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 90px;
  }
  .profileImg {
    margin-left: auto;
    height: 531px;
    width: max-content;
    object-fit: cover;
    object-position: top;
  }
  .absol {
    position: static;
    margin-top: 267px;
  }
  .name {
    max-width: 478px;
    font-size: 68px;
    line-height: 82px;
    color: #ffffff;
  }
  .details {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    margin-top: 2px;
  }
  .about {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.04em;
    color: #ffffff;
    margin-bottom: 12px;
    margin-top: 60px;
  }
  .explore {
    width: 390px;
    height: 80px;
    background: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 31px 26px;
    gap: 31px;
    cursor: pointer;
    margin-top: 30px;
  }
  .viewMore {
    width: 100%;
    height: 80px;
    background: linear-gradient(90deg, rgba(42, 68, 160, 0) 0%, #3353ce 100%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 31px 26px;
    gap: 31px;
    cursor: pointer;
  }
  .viewDetails {
    width: 100%;
    height: 58px;
    background: linear-gradient(90deg, rgba(42, 68, 160, 0) 0%, #3353ce 100%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 21px 26px;
    gap: 31px;
    cursor: pointer;
  }
  .exportxt {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    color: #ffffff;
  }
}
