.gridMr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 38px auto 0 auto;
  justify-content: center;
}
.joinCol {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  width: max-content;
  height: 35px;
  background: #0057d7;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.joinCol:disabled{
  opacity: 50%;
}
.boxColcard {
  box-sizing: border-box;
  width:100%;
  height: auto;
  background: #f7f7f7;
  border-radius: 0px 0px 2px 2px;
}
@media only screen and (max-width: 735px) {
  .gridMr {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
    margin: 0;
    justify-content: center;
  }
}
