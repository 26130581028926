.quote {
  width: 100%;
  padding: 57px 0;
}
.quote h1 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  padding: 0;
  margin: 0;
  text-align: left;
  text-transform: capitalize;
  letter-spacing: normal;
}
.switch {
  display: inline-flex;
  padding: 8px 8px 8px 20px;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background: #f3f0ff;
  width: 100%;
  margin-top: 18px;
}
.switch p {
  color: #514f73;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.switch button {
  padding: 8px 52px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border-radius: 8px;
  transition: all 0.6s ease-in-out;
}
.selected {
  background: rgba(55, 2, 141, 0.7);
  color: #f6f6f6;
}
.notSelected {
  color: #161616;
  background: #e8dbfc;
}
.question h3 {
  color: #514f73;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 134%; /* 24.12px */
}
.option {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: #f3f0ff;
  padding: 12px 32px 12px 15px;
}
.question input[type="radio"] {
  width: 20px;
  height: 20px;
  padding: 5px;
}
.question label {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}
.question button {
  border-radius: 8px;
  background: #37028d;
  padding: 12px 52px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  white-space: nowrap;
  flex-shrink: 0;
}
.question p {
  color: #514f73;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 17.42px */
}
.solution h3 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
}
.solution p {
  color: #514f73;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 134%; /* 24.12px */
}
.solution h4 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
}
.solution button {
  border-radius: 8px;
  background: #f3f0ff;
  padding: 10px 32px;
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-left: 0 !important;
}
.solution .numbers {
  border-radius: 100%;
  background: #37028d;
  padding: 0 10px;
  height: fit-content;
  color: #f6f6f6;
  font-family: "Clash Display", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
  flex-shrink: 0;
}

@media (max-width: 640px) {
  .switch {
    flex-direction: column;
  }
  .switch p {
    font-size: 18px;
    text-align: left;
    width: 100%;
  }
  .solution p{
    text-align: left !important;
  }
  .question p {
    text-align: center;
  }
  .solution button {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}
