.widthImg{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.page{
    background-color: white;
    width: 100%;
    height: 100vh;
}
.name{
    color: black;
}