.explore {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 31px 26px;
  gap: 31px;
  cursor: pointer;
}
.bgme{
  background: var(--secondary-color);
}
.viewMore {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, rgba(42, 68, 160, 0) 0%, #3353ce 100%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 31px 26px;
  gap: 31px;
  cursor: pointer;
}
.viewDetails {
  width: 100%;
  height: 58px;
  background: linear-gradient(90deg, rgba(42, 68, 160, 0) 0%, #3353ce 100%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 21px 26px;
  gap: 31px;
  cursor: pointer;
}
.exportxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.onHoverButton {
  display: none;
}
.flexico {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-left: 26px;
  padding-right: 26px;
}
.exploreView {
  display: none;
}
.grpOfAbsol {
  display: none;
}
@media only screen and (min-width: 865px) {
  .exploreView {
    display: block;
    width: 505px;
    height: 82px;
    background: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 31px 26px;
    gap: 31px;
    cursor: pointer;
  }
  .flexico {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 30px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .imgBackground {
    /* background: url(../../../assests/Group.png); */
    margin-bottom: 130px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .grpOfAbsol {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .onHoverButton {
    z-index: 15555;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    left: 50%;
  }
  .theViewMe {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.04em;
    color: #f5f5f5;
  }
}
