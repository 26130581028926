.newNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
}
.tailwLIOne {
  color: orange;
  padding: 16px;
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  background-color: black;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.textToFolow {
  position: relative;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  transition: color 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  cursor: pointer;
  color: #050608;
}

.fullLine > a:hover {
  text-decoration: none;
  color: #050608;
}

.fullLine {
  position: relative;
}

.fullLine > a:hover,
.fullLine > a:focus {
  cursor: pointer;
  border-bottom: 2px solid black;
  transform: scaleX(0) translateZ(0);
  transform-origin: top;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1),
    background-color 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s;
  content: "";
}

.lineHover:hover {
  cursor: pointer;
  border-bottom: 2px solid black;
  background-color: #fff;
  transform: scaleX(0) translateZ(0);
  transform-origin: top;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1),
    background-color 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s;
  content: "";
}

.nav {
  width: 1440px;
  height: 74px;
  background: #ffffff;
  /* backdrop-filter: blur(25px); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1100;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.theWorldLogo {
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
}

.fluidNav {
  z-index: 1100;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0px;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.navTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
  color: #111111;
}

.flexico {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.navLeft {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navRight {
  /* width: 50%; */
  height: 69px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.login {
  display: none;
}

.menuplace {
  position: absolute;
  right: 16px;
  top: 1%;
}

.launchme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  width: 149px;
  height: 37px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 62px;
}

.launchme > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.butun {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
  width: max-content;
  height: 54px;
  background: linear-gradient(90deg, #ff5065 -162.68%, #4930e9 63.32%);
  border-radius: 12px;
}
.menuplace {
  display: none;
}
.butun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.heading1 {
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.desciption1 {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 20px;
}
.campaignCard {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  position: relative;
}
.fileType {
  position: absolute;
  top: 12px;
  right: 12px;
  background: var(--primary-color);
  width: fit-content;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  text-transform: capitalize;
}
.plusOrMinusButton {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 32px;
  border: 1px solid #e8e8e8;
  background: rgba(244, 243, 243, 0.9);
  backdrop-filter: blur(12px);
  transition: background-color 0.2s ease-in;
}
.plusOrMinusButton:hover {
  cursor: pointer;
  background: rgba(192, 186, 186, 0.9);
}
.bagButton {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 40px;
  border: 1px solid rgba(58, 1, 151, 0.21);
  background: rgba(78, 4, 198, 0);
  backdrop-filter: blur(12px);
  flex-grow: 1;
  position: relative;
  transition: background-color 0.2s ease-in;
}
.dot {
  position: absolute;
  border-radius: 8px;
  background: #4e04c6;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
}
/* talewind md devices */
@media (max-width: 768px) {
  .heading1 {
    font-size: 21px;
    font-family: var(--font-secondary);
    font-weight: 600;
    letter-spacing: 0.64px;
  }
  .desciption1 {
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: 500;
    line-height: 24px;
  }
  .bagButton {
    width: 32px;
    height: 32px;
    padding: 6px;
    border-radius: 32px;
  }
}

/* talewind sm devices */
@media (max-width: 640px) {
  .campaignCard {
    max-width: 100%;
  }
}

@media only screen and (max-width: 880px) {
  .menuplace {
    display: block;
  }
  .textToFolow {
    font-size: 1rem;
  }

  .newNav {
    justify-content: flex-start;
    gap: 67px;
  }

  .butun {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    cursor: pointer;
    width: max-content;
    height: 54px;
    background: linear-gradient(90deg, #ff5065 -162.68%, #4930e9 63.32%);
    border-radius: 12px;
  }

  .butun > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: #ffffff;
  }

  .fluidNav {
    height: 54px;
  }

  .theWorldLogo {
    width: 72px;
  }

  .navLeft {
    justify-content: space-evenly;
  }

  .flexico {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .navRight {
    display: none;
  }

  .nav {
    height: 54px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .login {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
}
