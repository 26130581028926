.landing {
  background: #f2f0eb;
  text-align: left;
  min-height: 80vh;
  height: 100%;
}
.landing h3 {
  color: #514f73;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 134%; /* 24.12px */
}
.landing h1 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 69.68px */
  text-align: left;
}
.landing p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  opacity: 0.6;
}
.landing button {
  border-radius: 8px;
  background: #161616;
  padding: 12px 32px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  width: fit-content;
}
.influencer {
  font-weight: 400;
}
.influencer span {
  text-decoration-line: underline;
}
.infCard {
  width: 140px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 15px;
}
.infCard p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 15.4px */
}
.customerDiv {
  padding: 60px 0;
}
.customerDiv h2 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-align: left;
  margin: 0;
  padding: 0 20px;
}
.customerCard {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 2px rgba(0, 0, 0, 0.1);
  width: 280px;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
}
.customerCard img {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  object-fit: cover;
}
.customerCard h3 {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
  opacity: 1;
}
.customerCard p {
  color: #f6f6f6;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 17.42px */
  text-transform: uppercase;
}
.customerCard h4 {
  color: #f6f6f6;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-transform: uppercase;
}
.videoSection h2 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 52.8px */
  text-align: left;
  padding: 0;
  margin: 0;
}
.videoSection p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 21.44px */
}
@media (max-width: 640px) {
  .landing h1 {
    font-size: 32px;
  }
  .customerDiv h2 {
    font-size: 24px;
  }
  .videoSection h2 {
    font-size: 32px;
  }
}
