.campaignCard {
  min-height: 148px;
  width: 100%;
  height: auto;
  max-height: 229px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  display: flex;
}

@media (max-width: 768px) {
    .campaignCard{
        flex-direction: column;
        max-height: 350px;
    }
}