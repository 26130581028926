.matchpageheading {
  font-size: 32px;
  line-height: 140%;
}

.matchpagedesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.03em;
}

.matchcardname {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.matchcardday {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
}

.matchbetween {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
}

.bottomcardheading {
  font-style: normal;

  font-size: 32px;
  line-height: 140%;
}

.bottomcarddesc {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.04em;
}

.cardorgdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.04em;

  color: #4d4d4d;
}

.cardname {
  font-style: normal;

  font-size: 25px;
  line-height: 140%;
}

.formheadingone {
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */
}

.formheadingtwo {
  font-style: normal;

  font-size: 21px;
  line-height: 25px;
}
.clearSelectionParent {
  margin-top: 10px;
  width: 340px;
}
.clearSelectionInMobile {
  display: none;
}
.clearSelectionInWeb {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1b67fa;
  text-align: end;
}
.formSubHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.selectOrgButton {
  margin-right: 8px;
  margin-top: 10px;
  padding: 8px 24px;
  border: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.selectedOrgButton {
  border: none;
  background: #d2d0d0;
}
.selectOrgCloseButton {
  margin-left: 12px;
  width: 15px;
  height: 15px;
}
.forminput {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.textarea {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.formdesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.formdesctwo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.bottonfont {
  font-size: 16px;
}

.inputError{
  border: 1px solid red;
}
.error {
  margin-top: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  width: 100%;
}

.progresstext {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.03em;
}

.priceheading {
  font-weight: 600;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */

  letter-spacing: -0.03em;
}

.pricequote {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.pricequotetwo {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
}

.lastformcardheading {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  /* identical to box height, or 35px */

  letter-spacing: -0.03em;
}

.lastformcardlist {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.04em;
}

.middlecardshadow {
  box-shadow: 0px 0px 40px rgba(127, 34, 57, 0.2);
}
@media (max-width: 1100px) and (min-width: 768px) {
  .bottonfont {
    font-size: 14px;
  }
}

@media (max-width: 620px) {
  .formheadingone {
    font-size: 21px;
    line-height: 140%;
    /* or 29px */
  }

  .formheadingtwo {
    font-size: 16px;
    line-height: 19px;
  }
  .clearSelectionParent {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .clearSelectionInMobile {
    display: inline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1b67fa;
  }
  .clearSelectionInWeb {
    display: none;
  }
}

@media (max-width: 580px) {
  .matchcardday {
    font-weight: 600;
    font-size: 21px;
    line-height: 140%;
  }

  .matchbetween {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    /* or 39px */

    letter-spacing: -0.03em;
  }

  .extratext {
    display: none;
  }

  .bottonfont {
    font-size: 12px;
  }
}
