.campaigns h1 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 58.96px */
  letter-spacing: normal;
  text-align: left;
  text-transform: none;
}
.videoSection h2 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 52.8px */
  text-align: left;
  padding: 0;
  margin: 0;
}
.videoSection p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 21.44px */
}
/* talewind md  */
@media (max-width: 768px) {
  .campaigns h1 {
    font-size: 32px;
  }
  .videoSection h2 {
    font-size: 32px;
  }
}
