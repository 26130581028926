@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Zhi+Mang+Xing&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Creattion Demo";
  src: url("./Fonts/Creattion\ Demo.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: " Cochin";
  src: url("../src/Fonts/Cochin-LT-Font.ttf"); /* IE9 Compat Modes */
}

body {
  --primary-color: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
  --secondary-color: black;
  --third-color: white;
  --supporting-color: ;
  --font-primary: "Inter", sans-serif;
  --font-secondary: "Clash Display", sans-serif;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: #eaeaea;
  font-family: "Inter", sans-serif;
}
