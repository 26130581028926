.gridOfTwo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}
.oneGrid {
  width: 100%;
  height: 540px;
  background: linear-gradient(
    179.96deg,
    rgba(0, 0, 0, 0) 61.26%,
    #000000 99.96%
  );
  border-radius: 30px;
  position: relative;
}
.oneGrid > img {
  border-radius: 30px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 100%;
}
.absol {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  padding: 20px;
}
.contest {
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 140%;
text-align: left;
color: #FFFFFF;
}
.contestNmae {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
}
@media only screen and (max-width: 600px) {
  .gridOfTwo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 30px;
  }
  .oneGrid {
    height:241px;
  }
  .absol {
    padding: 8px;
    height: auto;
  }
  .contest {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 4px;
  }
  .contestNmae {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #ffffff;
  }
}