.banner {
  width: 100%;
  height: 660px;
  object-fit: cover;
  position: relative;
}
.thePiece {
  position: absolute;
  max-width: 550px;
  bottom: 20px;
  left: 60px;
  /* z-index: 100; */
}
.trensin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 12px;
}
.fifaHed {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  color: #ffffff;
}
.fifasub {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.buttn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 14px;
  width: max-content;
  height: 54px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 40px;
}
.mrore {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  letter-spacing: -0.03em;
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .banner {
    height: 710px;
  }
  .thePiece {
    position: absolute;
    max-width: 358px;
    bottom: 24px;
    left: 16px;
    z-index: 100;
    /* top: 56%; */
  }
  .fifaHed {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #ffffff;
  }
  .fifasub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.04em;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .trensin {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }
  .mrore {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    letter-spacing: -0.03em;
    color: #ffffff;
  }
}
