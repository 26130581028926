.backgroud {
  width: 100%;
  height: 170px;
  background: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
  position: relative;
  object-fit: cover;
}
.Backbutton {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 16px;
  top: 50px;
  cursor: pointer;
}
.Backbutton2 {
  position: relative;
  width: 50px;
  height: 50px;
  left: -16px;
  top: -150px;
}

.Share {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 16px;
  top: 50px;
}
.eventss {
  font-family: "Inter";
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  padding-top: 40px;
  color: #ef5050;
}

.bg {
  background-color: black;
  position: relative;
}
.bgs {
  background-color: black;
  position: relative;
  width: 100%;
  height: 54px;
  margin-top: 20px;
}
.parti {
  width: 185px;
  display: flex;
  height: 54px;
  padding: 15px 30px;
  position: absolute;
  right: 20px;
  border: none;
  background: #ef5050;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 60%);
  border-radius: 100px;
}
.btntext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #eaeaea;
  margin-top: 0;
}
.decflex {
  display: flex;
  justify-content: center;
}
.description {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
  color: #787878;
}
.gridig {
  grid-column-gap: var(--column-gutter);
  align-items: start;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  --column-gutter: 3px;
  --columns: 2;
  row-gap: var(--row-gutter);
  --row-gutter: 3px;
}
.gridigVid {
  grid-column-gap: var(--column-gutter);
  align-items: start;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  --column-gutter: 3px;
  --columns: 3;
}
.part {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 19px;
  padding: 20px;
  color: #787878;
}
.gridigc {
  grid-column-gap: var(--column-gutter);
  align-items: start;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  --column-gutter: 3px;
  --columns: 2;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: var(--row-gutter);
  --row-gutter: 2px;
  width: 10;
  object-fit: cover;
  position: relative;
}
.gridigcImg {
  width: 100%;
  height: 50vw;
  max-height: 350px;
  object-fit: cover;
  overflow: hidden;
}

.gridiVid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: var(--row-gutter);
  --row-gutter: 3px;
}

.gridigc > img {
  width: 100%;
  height: 50vw;
  max-height: 350px;
  object-fit: cover;
  overflow: hidden;
}
.gridiVid > img {
  width: 33.333333333333vw;
  /* height: 58vw; */
  object-fit: cover;
}
.videoimg {
  width: 33.333333333333vw;
  /* height: 58vw; */
  object-fit: cover;
}
.reletives {
  position: relative;
  /* height: 246px; */
}
.playbtn {
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 0px;
  top: 10px;
  right: 10px;
}
.likesandviews{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 30px;
  border-radius: 0px;
  bottom: 10px;
  left: 10px;
  font-size: 18px;
  opacity: .9;
}
.arrow {
  position: absolute;
  right: 29px;
  top: 21px;
}

.festivity {
  /* position: absolute; */
  width: 188.69px;
  /* height: 45px; */
  /* left: 100.15px;
  top: 293px; */
  margin-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  letter-spacing: -0.01em;

  background: linear-gradient(89.86deg, #a596ff 1.16%, #ffd6a0 93.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.rec1 {
  position: absolute;
  width: 172px;
  height: 172px;
  left: 27px;
  top: 94px;

  background: linear-gradient(180deg, #ad8bf7 0%, #eaad32 100%);
  /* opacity: 0.7; */
  filter: blur(170px);
  border-radius: 86px;
}
.rec2 {
  position: absolute;
  width: 172px;
  height: 172px;
  right: 0px;
  top: 250px;
  background: linear-gradient(180deg, #4b00ea 0%, #b90c74 100%);
  filter: blur(170px);
  border-radius: 86px;
}
.rec3 {
  position: absolute;
  width: 172px;
  height: 172px;
  left: 19px;
  top: 448px;

  background: linear-gradient(180deg, #ad8bf7 0%, #eaad32 100%);
  /* opacity: 0.7; */
  filter: blur(170px);
  border-radius: 86px;
}

.rules {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #787878;
  margin-left: 16px;
}
