.blackfooter {
  width: 100%;
  height: 451px;
  background-color: black;
  padding: 24px;
  position: relative;
}

.flexFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bottomline {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}

.fullCnetre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Appnaem {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 111px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  margin-top: 0;
}

.CompnyName {
  margin-bottom: 0;
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #FFFFFF;

  position: absolute;
  bottom: 24px;
  left: 24px;
}

.firstTxt {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstTxt>a:hover {
  color: grey;
  cursor: pointer;
}

.secondTxt {
  display: flex;
  flex-direction: column;

}

.secondTxt>a {
  text-decoration: none;
  cursor: pointer;
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
}

.secondTxt>a:hover {
  color: grey;
  cursor: pointer;
}


@media screen and (max-width: 480px) {
  .Appnaem {
    font-weight: 400;
    font-size: 2.5rem;
    font-family: "DIN", sans-serif;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-thickness: from-font;
    text-underline-position: from-font;
  }
}

/* .background {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
  border-top: 1px solid #d9d9d9;
}
.footerTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  max-width: 432px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 20px;
}
.contact {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
  color: #000000;
}
.numberandemail {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
}
.numberandemail:hover {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
.contactfLEX {
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.main {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  backdrop-filter: blur(50px);
}
.Logo {

  height: 40px;
  margin-bottom: 10px;
}
@media screen and (max-width: 480px) {
  .Logo {

    height: 36px;
  }
  .marginff{
    margin: 0 17px;
    padding-top: 20px;
    
  }
  .contactfLEX {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .Logo {

    height: 36px;
  }
  .marginff{
    margin: 0 17px;
    padding: 20px;
  }
  .background {

  }
  .contactfLEX {
    flex-direction: column;
    align-items: flex-start;
  }
} */