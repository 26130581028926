.AwardsText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.04em;
  color: #120A08;
  margin-bottom: 12px;
}
@media only screen and (min-width: 865px) {
  .AwardsText {
    color: #120A08;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.04em;
    margin-bottom: 16px;
  }
}
