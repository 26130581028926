.bgMe{
  background: var(--secondary-color);
}
.line {
  height: 1px;
  background: grey;
  margin-top: 20px;
  opacity: 0.3;
  border-radius: 30px;
}
.gridMr {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-top: 30px;
  }
  .singleGrid {
    width: 100%;
    height: 72px;
    background: transparent;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 14px 18px;
    display: flex;
    align-items: center;
  }
@media only screen and (min-width: 865px) {
  .gridMr {
    display: grid;
    grid-template-columns: 230px 230px 230px;
    gap: 30px;
    margin-top: 30px;
  }
  .singleGrid {
    width: 230px;
    height: 72px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 14px 18px;
    display: flex;
    align-items: center;
  }
}
