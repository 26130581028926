.newNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 100%;
}
.userButtun {
  background-color: white;
  border: 1px solid #dddddd;
  padding: 5px;
  border-radius: 21px;
  /* display: flex; */
  justify-content: center;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  display: none;
}
.tailwLIOne {
  color: orange;
  padding: 16px;
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  background-color: black;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.textToFolow {
  font-family: "Clash Display", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  /* font-feature-settings: 'liga' off; */
  color: #000000;
  cursor: pointer;
}

.fullLine > a:hover {
  text-decoration: none;
  color: #050608;
}

.fullLine {
  position: relative;
  display: flex;
}

.fullLine > a:hover,
.fullLine > a:focus {
  cursor: pointer;
  border-bottom: 2px solid black;
  transform: scaleX(0) translateZ(0);
  transform-origin: top;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1),
    background-color 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s;
  content: "";
}

.lineHover:hover {
  cursor: pointer;
  border-bottom: 2px solid black;
  background-color: #fff;
  transform: scaleX(0) translateZ(0);
  transform-origin: top;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1),
    background-color 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s;
  content: "";
}

.nav {
  width: 1440px;
  height: 74px;
  background: #ffffff;
  /* backdrop-filter: blur(25px); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1100;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.theWorldLogo {
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
}

.fluidNav {
  z-index: 100;
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0px;
  padding: 20px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  transition: background-color 300ms ease-in-out;
}

.navTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
  color: #111111;
}

.flexico {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.navLeft {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navRight {
  /* width: 50%; */
  height: 69px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.login {
  display: none;
}

.menuplace {
  position: absolute;
  right: 16px;
  top: 33%;
}

.launchme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  width: 149px;
  height: 37px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 62px;
}

.launchme > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.butun {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
  width: max-content;
  height: 54px;
  background: linear-gradient(90deg, #ff5065 -162.68%, #4930e9 63.32%);
  border-radius: 12px;
}
.menuplace {
  display: none;
}
.butun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #ffffff;
}

@media only screen and (max-width: 880px) {
  .menuplace {
    display: block;
  }
  .textToFolow {
    font-size: 21px;
    line-height: 26px;
  }
  .growtext {
    font-size: 17px;
    transition: font-size 0.2s ease-in-out;
  }

  .growtext:hover {
    font-size: 24px;
  }
  .newNav {
    padding-left: 20px;
    padding-right: 20px;
  }

  .butun {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    cursor: pointer;
    width: max-content;
    height: 54px;
    background: linear-gradient(90deg, #ff5065 -162.68%, #4930e9 63.32%);
    border-radius: 12px;
  }

  .butun > p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: #ffffff;
  }

  .fluidNav {
    height: 60px;
  }

  .theWorldLogo {
    width: 72px;
  }

  .navLeft {
    justify-content: space-evenly;
  }

  .flexico {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  .navRight {
    display: none;
  }

  .camva {
  }

  .nav {
    height: 54px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .login {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
  .userButtun {
    display: block;
  }
}
