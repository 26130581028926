.backgroundcntr {
  background-color: #171717;
  /* padding: 123px 100px 50px 100px; */
  /* max-width: 1280px;
  margin-left: auto;
  margin-right: auto;max-width: 1280px;
  margin-left: auto;
  margin-right: auto; */
}
.ribbon {
  background-color: white;
  height: 144px;
  margin-top: 50px;
}
.flexico{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  gap: 50px;

}
.regtttxtt {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: center;
  color: black;
  padding-top: 20px;
}
.videoWidthHt {
  width: 360px !important;
  height: 201px !important;
}

.logogoviral {
  align-self: flex-end;
  width: 84px;
}

.footerTerms {
  display: flex;
}

.footerTerms a {
  text-decoration: underline;
}

.videox {
  display: flex;
  gap: 26px;
}

.footer {
  display: flex;
  padding-bottom: 24px;
  justify-content: end;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.7);
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 100px;
  padding-right: 100px;
}
.yestophonereg {
  display: none;
}
.uplood {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.thetxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #bdbdbd;
  margin-bottom: 20px;
  text-align: center;
}
.listenPlay {
  width: 154px;
  height: 40px;
}
.listenpostining {
  cursor: pointer;
  position: absolute;
  right: 20px;
}
.gotovirl {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 10px 20px;
  gap: 10px;
  justify-content: center;
  width: max-content;
  height: 44px;
  background: #56b660;
  backdrop-filter: blur(50px);
  color: #000000;
  border-radius: 60px;
}
.uploadtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 140%;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
}
.aboutContest {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-top: 60px;
  margin-bottom: 20px;
}

.headings {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 135%;
  letter-spacing: -0.04em;
  color: rgba(255, 255, 255, 0.8);
}

.dataOfcontest {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #787878;
  margin-bottom: 20px;
}

.descriptionoftext {
  font-family: "Inter", sans-serif;
  display: inline;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #ababab;
}

.titles {
  max-width: 667px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #eaeaea;
}

.descriptionofLink {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* or 141% */

  letter-spacing: -0.04em;

  color: #8ea7ff;
}

.yestophone {
  display: none;
}

.explantion {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 350px;
  background: #252525;
  border-radius: 20px;
  cursor: pointer;
}

.titlesdata {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  max-width: 651px;
  margin-top: 20px;
  line-height: 140%;
  color: #787878;
}

.musicIcon {
  height: 31.999000549316406px;
  width: 32.000999450683594px;
}

.particpate {
  width: max-content;
  height: 44px;
  cursor: pointer;
  /* margin-top: 60px; */
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #d11b5d;
  border-radius: 60px;
  color: white;

}

.videoprofile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 26px;
  flex-wrap: wrap;
}

.song {
  max-width: 476px;
  height: 104px;
  gap: 20px;
  margin-top: 50px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background: #252525;
  border-radius: 10px;
  color: white;
}

.song > p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
}

.contestText {
  padding: 20px 16px 26px 16px;
}

.aboutCenter {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 0px 100px 50px 100px;
  /* padding-left: 40px;
  padding-right: 100px; */
}

.imgofexplain {
  height: 251px;
  border-radius: 20px 20px 0px 0px;
  background-color: #787878;
  object-fit: cover;
}
.regtttxt {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: center;
  color: white;
  margin-top: 120px;
}
.contestflexico {
  padding: 123px 100px 50px 100px;
  display: flex;
  gap: 60px;
  max-width: 1280px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.audiolon {
  position: absolute;
  right: 20px;
  width: 15vw;
}

.centerme {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* justify-content: center; */
}

.notophone {
  display: block;
}
.notophonereg {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px;xtt
  padding-left: 100px;
  padding-right: 100px; */
}

@media only screen and (max-width: 480px) {
  .flexico{
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
  }
  .yestophonereg {
    display: block;
  }
  .regtttxtt{
    display: none;
  }
  .uploadtext {
    font-size: 17px;
  }
  .regtttxt {
    margin-top: 60px;
    font-size: 17px;
  }
  .footer {
    justify-content: center;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .uplood {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .aboutCenter {
    padding-left: 0;
  }
  .footer > img {
    width: 45px;
  }

  .notophone
  /* .notophonereg  */
  {
    display: none;
  }

  .videoWidthHt {
    width: 274px !important;
    height: 152px !important;
  }

  .yestophone {
    display: block;
  }

  .backgroundcntr {
    background-color: #171717;
    width: 100%;
    padding: 100px 20px 26px 20px;
  }

  .centerme {
    justify-content: unset;
  }

  .particpate {
    margin: 20px auto;
  }

  .explantion {
    height: 581px;
  }

  .contestflexico {
    gap: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;  
    padding:0;
  }

  .titles {
    width: 342px;
    font-weight: 600;
    font-size: 25px;
    line-height: 140%;
    /* or 35px */
    letter-spacing: -0.03em;
  }

  .titlesdata {
    display: none;
  }

  .aboutContest {
    margin-top: 0;
  }

  .song {
    width: 230px;
    margin-top: 0;
    background: #171717;
    border-radius: 50px;
    height: 36px;
  }

  .song > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    line-height: 140%;
  }

  .flla {
    display: flex;
    margin-top: 18px;
    align-items: center;
    justify-content: space-around;
  }

  .audiolon {
    height: 36px;
    width: 143px;
    position: static;
  }

  .musicIcon {
    height: 24px;
    width: 24px;
  }
  .ribbon {
    background-color: transparent;
    height: auto;
    margin-top: 40px;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 900px) {
  .song {
    width: 52.344vw;
  }
}
