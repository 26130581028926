.phonefull {
  min-height: 100vh;
  width: 100%;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  padding-left: 16px;
  padding-right: 16px;
}

.logodiv {
  position: relative;
  display: flex;
  justify-content: center;
}

.logosign {
  position: absolute;
  top: 113px;
  text-align: center;
}

.logosign > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
}

.phnumSect {
  position: absolute;
  top: 374px;
}

.phnum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #787878;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.radios {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #b5b5b5;
  padding-left: 68px;
  position: relative;
  top: 411px;
  box-sizing: border-box;
  background: rgba(37, 37, 37, 0.8);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countrycode {
  position: absolute;
  left: 34px;
}
.countrycode{
    position: absolute;
    top: 29%;
    left: 5%;
    z-index: 5;
    color: #B5B5B5;
}

.inputtt {
  background: transparent;
  /* position: absolute; */
  width: 100%;
  /* left: 74px; */
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  /* backdrop-filter: blur(50px); */
}

.tick {
  position: absolute;
  right: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin: 0;
  border: none;
}

.radiosBue {
  position: relative;
  top: 441px;
  box-sizing: border-box;
  background: #5073ef;
  backdrop-filter: blur(50px);
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.radiosBueLoading {
  position: relative;    
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rewqq {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.BackBUttonParent {
  margin-top: 10px;
}
.Backbutton {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.festivity {
  /* position: absolute; */
  width: 188.69px;
  height: 45px;
  /* left: 100.15px;
    top: 293px; */
  margin-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  letter-spacing: -0.01em;

  background: linear-gradient(89.86deg, #a596ff 1.16%, #ffd6a0 93.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.phoneTag {
  width: 258px;
  height: 60px;
  background: rgba(37, 37, 37, 0.8);
  color: #b5b5b5;
  backdrop-filter: blur(50px);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  text-indent: 10px;
}
.phoneCountryTag {
  width: 70px;
  height: 60px;
  background: rgba(37, 37, 37, 0.8);
  color: #b5b5b5;
  backdrop-filter: blur(50px);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  text-indent: 10px;
}

.otpSubmit{
    margin-top: 30px;
    background: #5073EF;
border: 1px solid #5073EF;
backdrop-filter: blur(50px);
padding: 18px 120px;

border-radius: 95px;
}
.greenTick{
    position: absolute;
    top: 29%;  
    right: 5%;
    z-index: 5;
}