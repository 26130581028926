.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}
.container {
  width: 100%;
  height: 100vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container h2 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
  padding: 0;
  text-align: left;
}
.contentDiv {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid var(--supporting-color);
  position: sticky;
  top: 0;
}

.headerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerLeft img {
  padding: 10px;
  border: 1px solid var(--supporting-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-right: 16px;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  /* height: 82%; */
  height: 100%;
  overflow: auto;
  color: #000;
}
.body h3 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
}
.body h4 {
  color: #8c8c8c;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: normal;
}

.infCollab {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
}

.infCollab pre,
.infCollab p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: normal;
}
.formGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  padding: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.form p {
  color: #8c8c8c;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.form textarea {
  field-sizing: content;
  min-height: 100px;
  resize: none;
  overflow: auto;
}
.form input,
.form textarea {
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 10px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.form textarea {
  field-sizing: content;
}

.form input:focus,
textarea:focus {
  border: 1px solid #000000;
}
.form .error {
  border: 1px solid rgb(255, 41, 41);
}
.form .errorText {
  font-size: 12px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 16px;
  color: #fa6b6b;
  width: 100%;
  text-align: right;
}
.imageContainer {
  position: relative;
  height: 100px;
  border-radius: 8px;
  border: 1px solid rgba(140, 140, 140, 0.3);
  padding: 5px;
}
.imageContainer button {
  border: 1px solid rgba(140, 140, 140, 0.2);
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #fff;
}
.changeImageButton {
  background: #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 8px;
  border: 1px solid rgba(140, 140, 140, 0.2);
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  text-transform: uppercase;
}
.buttonWrapper {
  background: #ffffff;
  width: 100%;
  /* height: 94px; */
  position: sticky;
  border-top: 1px solid var(--supporting-color);
  bottom: 0;
  right: 0;
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  z-index: 1;
}
.buttonWrapper .dbButton {
  background: #fff !important;
  border: 1px solid #161616 !important;
  color: #161616 !important;
}

.buttonWrapper button {
  padding: 12px 32px;
  border-radius: 8px;
  background: #161616;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.buttonWrapper p {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 16px;
  color: #fa6b6b;
  width: 100%;
}
.signIn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 16px;
}
.signIn p {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
  letter-spacing: 0.1em;
  text-align: center;
}
.signIn button {
  padding: 12px 32px;
  border-radius: 8px;
  background: #161616;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

@media (max-width: 768px) {
  .container {
    padding: 30px 16px;
  }
  .formGrid {
    display: flex;
    flex-direction: column;
  }
  .infCollab {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .buttonWrapper {
    flex-wrap: wrap;
    padding: 18px;
  }
  .buttonWrapper button {
    padding: 8px 15px;
  }
}
