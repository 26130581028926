.TheCircle {
  position: absolute;
  top: 29px;
  width: 174px;
  height: 140px;
  object-fit: cover;
  left: 0;
  background: #5c59c2;
  filter: blur(110px);
  border-radius: 100px;
}
.bgMe{
  background: var(--secondary-color);
}
.profileMe {
  position: relative;
  z-index: 11000;
  width: 100%;
  max-height: 573px;
  object-fit: cover;
}
.cpmg {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  padding: 0 26px;
}
.poster {
  width: 100%;
  object-fit: cover;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;

}
.cmpgName {
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.04em;
  text-align: left;

  color: #ffffff;
}

.explore {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 31px 26px;
  gap: 31px;
  cursor: pointer;
}
.gridxxx{
  display: grid;
  grid-template-columns: 1fr; 
  padding-left: 0x;
  padding-right:0px;
  margin-top:30px;
  gap: 20px;
}
.exportxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.viewContestText {
  display: none;
}
@media only screen and (min-width: 865px) {
  .profileMe {
    max-height: 717px;
  }
  .cpmg {
    font-size: 68px;
    line-height: 82px;
    padding: 0 100px;
  }
  .gridxxx{
    display: grid;
    grid-template-columns: 295px 295px 295px 295px 295px; 
    padding-left: 100px;
    padding-right: 100px;
    margin-top:30px;
    gap: 20px;
  }
  .viewContestText {
    font-family: "Inter";
    font-style: normal;
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    color: #1455d2;
    display: block;
    cursor: pointer;
  }
}
