.NavBar {
  background: linear-gradient(90.66deg, #0077ab 55.04%, #66019d 92.45%);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 75px;
  opacity: 0.8;
  /* display: flex;
 
  align-items: center; */
  padding: 0 20px;
  position: fixed;
  z-index: 100;
}

@media only screen and (max-width: 600px) {
  .NavBar {
    width: 100%;
    padding: 0 24px;
  }
}

.loog {
  height: 40px;
  width: auto;
  cursor: pointer;
}
