.gridmE {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 20px;
}
.bgMe {
  background: var(--third-color);
}
.singleGrid {
  cursor: pointer;
  display: flex;
  gap: 14px;
  align-items: center;
  background:  var(--third-color);
  border: 1px solid rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(10px);
  padding: 11px 10px;
  border-radius: 100px;
  justify-content: center;
}
.flexico {
  display: flex;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  gap: 37px;
  flex-direction: column;
}
@media only screen and (min-width: 865px) {
  .gridmE {
    display: none;
  }
  .flexico {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    gap: 92px;
    flex-direction: row;
  }
}
