.background {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* background: #009c9a; */
  backdrop-filter: blur(50px);
  padding-top: 70px;
}
.orgLogo{
  position: absolute;
  width: 60px;
  left: 199px;
  top: 70px;
}
.SingleimgWidths{
  object-fit: cover;
  max-height:400px ;
}
.singleflexcio {
  position: relative;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  /* background: #009c9a; */
  backdrop-filter: blur(50px);
}
.flexico {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 20px; */
  align-items: flex-end;
}
.welcome {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #ffffff;
  padding-bottom: 10px;
}
.data {
}
.welcomePara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.03em;
  color: #ffffff;
  max-width: 622px;
  padding-bottom: 20px;

}
.dateOfprgrm {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: left;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.givway {
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 6px;
}
.posterOne {
  width: 410px;
  height: 408px;
}
.posterTwo {
  height: 460px;
  object-fit: cover;
}
.buttun {
  cursor: pointer;
  width: 326px;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.3);
  border-radius: 80px;
}
.buttun > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #014746;
}
@media screen and (max-width: 480px) {
  .orgLogo{
    left: 0;
    width: 45px;
  }
  .SingleimgWidths{
    height: auto;
  }
  .flexico {
    flex-direction: column;
    align-items: center;
  }
  .dateOfprgrm {
    font-size: 17px;
    line-height: 140%;
    text-align: center;
  }
  .welcome {
    font-size: 25px;
    line-height: 140%;
    padding-right: 35px;
    text-align: center;
    padding-left: 35px;
  }
  .buttun {
    width: 237px;
    height: 48px;
  }
  .givway {
    margin-bottom: 20px;
    margin-top: 17px;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    color: #ffffff;
  }
  .buttun > p {
    font-size: 16px;
    line-height: 140%;
  }
  .main {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 27px;
    /* background: #009c9a; */
    backdrop-filter: blur(50px);
    /* padding-top: 45px; */
  }
  .welcomePara{
    text-align: center;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .tabgiveaway{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .orgLogo{
    left: 87px;
  }
  .welcomePara{
    text-align: center !important;
    margin: auto !important;
  }
  .flexico {
    flex-direction: column;
    align-items: center;
  }
  .dateOfprgrm {
    font-size: 17px;
    line-height: 140%;
    text-align: center;
  }
  .welcome {
    font-size: 25px;
    line-height: 140%;
    padding-right: 35px;
    text-align: center;
    padding-left: 35px;
  }
  .buttun {
    width: 237px;
    height: 48px;
  }
  .givway {
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    color: #ffffff;
  }
  .buttun > p {
    font-size: 16px;
    line-height: 140%;
  }
  .main {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 27px;
    /* background: #009c9a; */
    backdrop-filter: blur(50px);
  }
}
