.dispatchFlex {
  display: block;
}

@media only screen and (max-width: 600px) {
  .dispatchFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 23px;
  }
}
