.navcontents {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.sectiononeheading1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  letter-spacing: -0.03em;

  color: #ffffff;
}

.sectiononeheading2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #ffffff;
}

.sectiononeheading3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  color: #ffffff;
}

.sectiontwoheading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  margin-bottom: 40px;
  /* identical to box height, or 45px */

  letter-spacing: -0.03em;
}

.cardorgdesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.04em;

  color: #4d4d4d;
}

.cardname {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  /* or 35px */

  letter-spacing: -0.03em;
}

.formheadingone {
  
  font-size: 40px;
  line-height: 48px;
 
}

.formheadingtwo {
  font-style: normal;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: -0.03em;
  line-height: 25px;
}

.forminput {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.bottomcardheading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  letter-spacing: -0.03em;
}

.bottomcarddesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.box::-webkit-scrollbar {
  display: none;
}

.showhamburger {
  display: none;
}

.menu {
  transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  transition-delay: 100ms;
}

.homebg {
  /* background-image: url('../../assests/newHome/homebg.svg'); */
  height: 690px;
  width: 100vw;
  object-fit: cover;
  /* background-repeat: no-repeat; */
}

.shape {
  background-image: url("../../assests/newHome/shapes.svg");
  height: 100%;
  width: 100%;
}

.shapeone {
  background-image: url("../../assests/newHome/shapeone.svg");
  height: 300px;
  width: 300px;
}

.shapetwo {
  background-image: url("../../assests/newHome/shapetwo.svg");
  height: 300px;
  width: 300px;
}

@media (max-width: 620px) {
  .hidetohamburger {
    display: none;
  }

  .showhamburger {
    display: block;
  }

  .sliding {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}

@media (max-width: 1024px) {
  .hidebelow {
    display: none;
  }
}
