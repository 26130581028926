.homepage {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    justify-content: center;


}

.contestHeadbegin {
    margin-top: 33px;
    /* margin-bottom: 33px; */

}

/* .widthController{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
} */

.cover-top-shade {

    width: 100%;
    height: 58px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 10.83%, #000000 100%);
    opacity: 0.6;

}

.campaignName {
    line-height: 31px;
    font-weight: 500;
    font-size: 24px;
}

.conducted-by {
    color: #787878;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.about-button {
    background: #171717;
    color: #EAEAEA;
    border-radius: 30px;
    height: 31px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 20px;
    margin-bottom: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.about-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #EAEAEA;

}

.about-arrow {
    width: 5px;
    height: 9px;
}

.backdrop {
    background: rgba(9, 9, 9, 0.47);
}

.dropshadow {
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.3));
}

.rules {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #787878;
}