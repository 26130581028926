.bgMe {
  background: var(--third-color);
}

.TheCircle {
  position: absolute;
  top: 29px;
  width: 174px;
  height: 140px;
  object-fit: cover;
  left: 0;
  background: #5c59c2;
  filter: blur(110px);
  border-radius: 100px;
}
.contact {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  padding-top: 110px;
  color: #120a08;
}
.gridmE {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 20px;
  margin-top: 20px;
}
.singleGrid {
  cursor: pointer;
  display: flex;
  gap: 14px;
  align-items: center;
  background: var(--third-color);
  border: 1px solid rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 100px;
  padding: 11px 10px;
  border-radius: 100px;
  justify-content: center;
}
.emailAddress {
  width: 100%;
  padding: 2px;
  margin-top: 30px;
  height: 50px;
  background: radial-gradient(
    100% 2376.56% at 0% 5.63%,
    #985fbb 0%,
    #d6c75b 26.04%,
    #52aa56 50.52%,
    #3cd5db 74.48%,
    #51d9a2 100%
  );
  position: relative;
  border-radius: 50px;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}
.singleBlack {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  padding: 17px 20px;
  display: flex;
  align-items: center;
  background: var(--third-color);
  color: #120a08;
}
.arrow {
  width: 40px;
  margin-left: auto;
  height: 100%0;
  background: #304fc0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 11px;
  position: absolute;
  right: 5px;
  border-radius: 40px;
  top: 4px;
}
.explore {
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #2a44a0 0%, #3353ce 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 31px 26px;
  gap: 31px;
  cursor: pointer;
}

.exportxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
@media only screen and (min-width: 865px) {
  .drow {
    background: white;
    border-radius: 50px;
    width: 521px;
    height: 50px;
    border: 1px solid black;
  }
  .resend {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
    margin-top: 20px;
    color: black;
  }
  .resend > a {
    font-weight: 600;
    font-size: 11px;
    color: #2a44a0;
  }
  .inputOtp {
    width: 231px;
    height: 50px;
    background: #F2F2F2;
    border-radius: 44px;
    margin-top: 10px;
  }
  .inputOtp > input {
    width: 231px;
    height: 50px;
    background: #F2F2F2;
    border-radius: 44px;
    padding: 14px 80px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    color: black;
  }
  .oneOption {
    background: transparent;
    border-radius: 50px;
    height: 48px;
    padding: 0 24px;
    color: black;
    width: 100%;
  }
  .inputTag {
    box-sizing: border-box;
    width: 520px;
    height: 54px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(27px);
    border-radius: 50px;
    padding: 16px 24px;
    border: 1px solid black;
    margin-bottom: 16px;
    color: #120a08;
  }
  .inputTag ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.04em;
    color: #120a08;
    opacity: 0.6;
  }
  .emailAddress {
    width: 520px;
    margin-top: 30px;
  }
  .gridmE {
    display: grid;
    grid-template-columns: repeat(2, 230px);
    column-gap: 8px;
    row-gap: 20px;
    margin-top: 20px;
  }
  .singleGrid {
    height: 72px;
    border-radius: 10px;
    align-items: flex-start;
    padding: 12px 18px;
    justify-content: flex-start;
  }
  .explore {
    justify-content: flex-start;
    padding: 31px 100px;
  }
}
