.container {
  display: flex;
  gap: 5px;
}

.digit {
  background: #81288f;
  padding: 12px 8px;
  color: white;
  font-size: 18px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 39.68px */
  letter-spacing: -1.28px;
}
