.three{
  font-weight: 300;
}

.four{
  font-weight: 400;
}

.five{
  font-weight: 500;
}

.six{
  font-weight: 600;
}

.progress{
  transition: background-color 0.4s ease-out;
}

.scroll {
  background-color: white;
  color: black;
}
.url{
  font-family: "Inter";
}