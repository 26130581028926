.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 50px 50px 70px 50px;
}
.parentDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(37, 37, 37, 0.6);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
@media (max-width: 640px) {
  .popupWrapper {
    padding: 20px;
  }
  .parentDiv {
    height: 50%;
  }
}
