.backgroundME {
  width: 100%;
  background: url(../../../assests/green.png);
  height: 550px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.profileImg {
  margin-left: auto;
  height: 531px;
  object-fit: cover;
  object-position: top;
}
.absol {
  position: absolute;
  left: 26px;
  top: 399px;
}
.name {
  max-width: 338px;
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  opacity: 0.6;
}
.nameAgain {
  max-width: 338px;
  font-family: "Creattion Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 64px;
  color: #ffc727;
  position: absolute;
  top: -10px;
  display: block;
}
.details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 2px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.gridMr {
  display: none;
}
@media only screen and (min-width: 865px) {
  .gridMr {
    display: grid;
    grid-template-columns: 230px 230px;
    gap: 30px;
    margin-top: 30px;
  }
  .singleGrid {
    width: 230px;
    height: 72px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 14px 18px;
    display: flex;
    align-items: center;
  }
  .details {
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .name {
    max-width: 500px;
    font-style: normal;
    font-weight: 400;
    font-size: 68px;
    line-height: 82px;
    color: #ffffff;
    opacity: 1;
  }
  .absol {
    position: static;
    margin-top: 200px;
  }
  .backgroundME {
    display: flex;
    flex-direction: row-reverse;
    height: auto;
    background: black;
    padding-left: 100px;
    justify-content: space-between;
  }
  .nameAgain {
    display: none;
  }
}
