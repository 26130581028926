.signup-button{
    /* width: 358px;
height: 60px;
border: 1px solid #B5B5B5;
border-radius: 5px;
font-weight: 400;
font-size: 17px;
line-height: 24px;
text-align: center;
color: #B5B5B5; */

box-sizing: border-box;

/* position: absolute; */
width: 358px;
height: 60px;
/* left: 16px;
top: 532px; */

background: rgba(0, 0, 0, 0.3);
border: 1px solid #B5B5B5;
backdrop-filter: blur(100px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 95px;
} 

