.landing {
  background: #f2f0eb;
  text-align: left;
  min-height: 70vh;
  height: 100%;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.landing h1 {
  color: #fff;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 58.96px */
  letter-spacing: normal;
  text-transform: none;
}
.landing p {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
}
.landing img {
  width: 255px;
  height: 255px;
}
.contestContainter h2 {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
}

.contestContainter button {
  border-radius: 8px;
  background: #161616;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 12px 32px;
}
.facts h2 {
  color: #000;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 53.6px */
}
.facts p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
}
.card {
  border-radius: 16px;
  background: #bfcaff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
}
.card h3 {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
  opacity: 1;
}
.card p {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
  text-align: left;
  opacity: 0.6;
}
.card img {
  width: 100%;
  mix-blend-mode: darken;
}
.usesHeading {
  color: #000;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  letter-spacing: 0.01em;
  text-transform: none;
  margin-bottom: 60px;
}
.uses {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 6%;
  justify-content: center;
}
.uses h4 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
  text-align: center;
}
.uses p {
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}

.videoSection h2 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 52.8px */
  text-align: left;
  padding: 0;
  margin: 0;
}
.videoSection p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 21.44px */
}
@media (max-width: 768px) {
  .landing h1 {
    font-size: 33px;
  }
  .landing p {
    font-size: 13px;
  }
  .facts h2 {
    font-size: 28px;
  }
  .facts p {
    font-size: 13px;
  }
  .usesHeading {
    font-size: 21px;
    margin-bottom: 40px;
  }
  .card {
    width: 90%;
  }
  .uses {
    display: flex;
    overflow: auto;
    justify-content: start;
    scroll-snap-stop: always;
  }
  .videoSection h2 {
    font-size: 32px;
  }
}
