.phonefull {
  position: absolute;
  top: 0px;
  height: 100vh;
  width: 100%;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-height: 100vh;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 10;
}
@media only screen and (max-width: 600px) {
  .phonefull {
    position: fixed;
  }
}
.logodiv {
  position: relative;
  display: flex;
  justify-content: center;
}

.logosign {
  position: absolute;
  /* top: 113px; */
  top: 90px;
  text-align: center;
  width: 188.69px
}

.logosign > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
}

.phnumSector {
  position: relative;
  /* top: 278px; */
  top: 255px;
}

.phnumSect {
  text-align: center;
  display: flex;
  justify-content: center;
}

.otpEnter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.phnum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #787878;
  margin-top: 10px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.radios {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #b5b5b5;
  /* padding-left: 68px; */
  position: relative;
  margin-top: 30px;
  box-sizing: border-box;
  background: rgba(37, 37, 37, 0.8);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countrycode {
  position: absolute;
  left: 34px;
}

.inputtt {
  background: transparent;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  text-align: center;
  /* backdrop-filter: blur(50px); */
}

.tick {
  position: absolute;
  right: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin: 0;
  border: none;
}

.radiosBue {
  position: relative;
  /* top: 411px; */
  margin-top: 40px;
  box-sizing: border-box;
  background: #5073ef;
  backdrop-filter: blur(50px);
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.radiosBueLoading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rewqq {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.Backbutton {
  margin-top: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.festivity {
  /* position: absolute; */
  width: 188.69px;
  /* height: 45px; */
  /* left: 100.15px;
  top: 293px; */
  margin-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */
  letter-spacing: -0.01em;
  background: linear-gradient(89.86deg, #a596ff 1.16%, #ffd6a0 93.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}
