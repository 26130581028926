.letsStart {
  display: flex;
  width: 240px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #4f52e6;
  color: #fff;
}
.text {
  color: #0c0134;
  font-family: Inter;

  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}
.hereWeAre {
  color: #4f52e6;
  font-family: "General Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 44px */
  letter-spacing: -1.76px;
}
.brandsText {
  color: #121212;
  font-family: Inter;

  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.96px;
}

.Tailored {
  color: #121212;
  font-family: Inter;

  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.96px;
}
.tailorP {
  color: #0c0134;
  font-family: Inter;

  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.52px;
}
