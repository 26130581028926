.megaText {
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 64px;
  color: #000000;
  max-width: 573px;
}
.theGrtAbsolute {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, -60px);
  padding-left: 0px;
  padding-right: 0px;
}
.viewMe {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  width: 130px;
  height: 35px;
  background: #111111;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.LearnMe {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  width: max-content;
  height: 35px;
  background: #111111;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.theLogBox {
  /* transition: box-shadow 0.2s ease; */

  cursor: pointer;
  margin: 0;
  text-align: inherit;
  width: 300px;
  position: absolute;
  color: #000000;
  top: 65px;
  right: 0;
  min-height: 200px;
  height: auto;
  padding: 20px;
  background: #d5d5d5;
  border: 1px solid #dddddd;
  border-radius: 0 0 21px 21px;
}
.alwaysOnTop {
  position: sticky;
  /* left: 50%; */
  top: 10%;

  /* transform: translateX(-50%); */
  width: 100%;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.singleIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
  width: 120px;
  cursor: pointer;
  height: 60px;
}
.goviralFooter {
  font-family: "Clash Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
  font-feature-settings: "liga" off;
  color: #fb6c44;
  text-align: left;
  margin-left: 60px;
}
.FooterTxt {
  max-width: 211px;
  height: 64px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #787878;
}
.goviralFooterTxt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #4e04c6;
  max-width: 211px;
}
.roundAbout {
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.clashFont {
  font-family: "Clash Display", sans-serif;
}
.viewContedt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  width: max-content;
  height: 35px;
  background: #7c7c7c;
  border-radius: 4px;
  cursor: pointer;
}

.slider::-webkit-scrollbar {
  display: none;
}
.slider.active {
  cursor: grabbing;
}
.absoDes {
  position: absolute;
  bottom: 17px;
  left: 19px;
  max-width: 238px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #787878;
}
.overMe {
  margin-left: -10px;
}
.overMe:first-child {
  margin-left: 0;
}
.gridOftwo {
  display: grid;
  grid-template-columns: repeat(2, 450px);
  gap: 40px;
  margin-top: 60px;
}
.gridOfTesti {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .theGrtAbsolute {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    bottom: 20px;
    transform: translate(-50%, -20px);
  }
  .theLogBox {
    position: fixed;
    top: 58px;
    width: 100%;
    height: 100%;
  }
  .singleIcon {
    width: 70px;
  }
  .goviralFooter {
    margin-left: 0;
  }
  .megaText {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
  .gridOfTesti {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  .gridOftwo {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
  .viewContedt {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 16px;
    width: max-content;
    height: 35px;
    background: #7c7c7c;
    border-radius: 4px;
  }
  .roundAbout {
    border-radius: 100%;
    width: 26px;
    height: 26px;
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    color: #ffffff;
  }
}
