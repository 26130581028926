.influencerShareGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
}
.influencerShareGrid input,
.influencerShareGrid textarea {
  border: 1px solid #787878;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0 10px;
  color: #000000;
}
.influencerShareGrid input:focus,
.influencerShareGrid textarea:focus {
  border: 2px solid #787878;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0 10px;
  color: #000000;
}
.serviceformbg {
  background: rgba(34, 34, 34, 0.6);
  /* backdrop-filter: blur(50px); */
}
