.Banner {
  position: relative;
  padding: 53px 120px;
}
.cupico{
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 21px;
}
.creatContest{
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;
}
.tint{
  position: absolute;
  width: 100%;
  background: #7F2239;
  mix-blend-mode: multiply;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
.fflexToCentre{
  gap: 40px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.imgBanner {
  width: 100%;
  height: 226px;
  object-fit: cover;
  object-position:top;
  /* position: relative; */
}

.imgBannerNew {
  width: 100%;
  height: 698px;
  object-fit: cover;
  position: relative;
  object-position: bottom;
}

.inWriter {
  position: absolute;
  top: 50%;
  /* margin-left: auto;
  margin-right: auto; */
  left: 50%;
  transform: translate(-50%, -50%);
}

.fflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 1320px;
}

.butun {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  width: 217px;
  height: 54px;
  background: #2446fe;
  border-radius: 12px;
  cursor: pointer;
}

.butun>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #ffffff;
}

.fifaFlex {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 788px;
}
.plusMe{
  width: 40px;
  cursor: pointer;
}
.quter {
  width: 174px;
  margin-bottom: 6px;
}

.create {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 84px;
  width: 1193px;
  letter-spacing: -0.04em;
  color: #FFFFFF;
}

.mobleAbs {
  display: none;
}

@media only screen and (max-width: 880px) {
  .plusMe{
    width:25px;
  }
  .create {
    width:auto;
    font-size: 1rem;
    text-align: center;
line-height: 35px;
  }
  .imgBanner {
    object-position: center;
    width: 100%;
    height: 320px;
  }

  .mobleAbs {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 30px;
    padding: 0 16px;
  }

  .butun {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    width: 100%;
    height: 54px;
    background: transparent;
    border-radius: 12px;
  }
}