.buttonContainer {
  background: #fff;
  color: #000;
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
  margin-top: 30px;
  padding: 20px;
}
.buttonContainer button {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}
