.gallery {
  /* column-count: 4;
  column-gap: 20px; */
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.eachImg {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
}

.campaign {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: black;
  margin-bottom: 4px;
  margin-top: 11px;
  text-transform: uppercase;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.decript {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .imgDiv:nth-child(4n+1){
    clear: left;
} */
.imgDiv {
  /* padding-bottom: 20px; */
  position: relative;
}

.tint {
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 81.68%, #000000 100%);
  opacity: 0.6;
  bottom: 0;
  height: 250px;
  width: 100%;
  z-index: 555;
}

.absol {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  z-index: 556;
}

.imgDiv {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.coponetHt {
  margin-top: 30px;
}

.viral {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #111111;
  margin-bottom: 16px;

  text-align: center;
}

.theViralDescr {
  max-width: 673px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #111111;
  margin: 0 auto;
}

.flexme {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

@media only screen and (max-width: 880px) {
  .campaign {
    font-size: 1rem;
  }
  .gallery {
    /* column-count: 4;
    column-gap: 20px; */
    padding-left: 16px;
    padding-right: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 8px;
  }
  

  .absol {
    padding: 9px;
  }
}