.background {
  background: #fff;
  color: #000;
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  text-decoration: none;
}
.innerContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1280px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: min(187px, 15%);
  padding-left: min(187px, 15%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 500;
}
.background button {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}
.bagButton {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 40px;
  border: 1px solid rgba(58, 1, 151, 0.21);
  background: rgba(78, 4, 198, 0);
  backdrop-filter: blur(12px);
  transition: background-color 0.2s ease-in;
}
@media (max-width: 768px) {
  .innerContainer {
    padding: 16px;
    font-size: 12px;
  }
  .background button {
    padding: 8px 12px;
  }
  .bagButton {
    width: 32px;
    height: 32px;
    padding: 6 px;
    border-radius: 32px;
  }
}
