.container {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100%;
  max-width: 906px;
  max-height: 606px;
  height: 63vh;
  margin: 30px auto 60px auto;
  display: flex;
  color: #000;
}
.container button {
  padding: 12px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  font-size: 15px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: fit-content;
}
.image {
  flex-basis: 30%;
  width: 100%;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.content {
  flex-basis: 70%;
  width: 100%;
  height: 100%;
  padding: 6% 4% 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.heading1 {
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
  margin-bottom: 16px;
}
.desciption1 {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 24px;
  color: #787878;
}

.phoneInput:focus {
  border: 1px solid #4e04c6;
}
.phoneInput:focus-within {
  border: 1px solid #4e04c6;
}
.phoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #fff;
  width: 80%;
  margin-top: 12px;
}

.phoneInput input {
  margin: 0;
  padding-right: 0;
  width: 100%;
  padding: 16px 12px;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
}

.phoneInput input:first-child {
  width: 16%;
}
.phoneInput .otpInput {
  width: 100% !important;
}
.phoneInput input:focus {
  border: none;
}
.phoneInput label {
  font-size: 15px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.verticalLine {
  height: 32px;
  width: 1px;
  border-radius: 30px;
  background: #dedede;
}

.inputError {
  border: 1px solid #ff9696 !important;
}
.error {
  color: #ff4343;
  font-size: 12px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 10px;
}
.numberSent {
  color: #787878;
  font-size: 13px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}
.resendOtp {
  color: #787878;
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
@media (max-width: 640px) {
  .container {
    flex-direction: column;
    text-align: center;
  }
  .heading1 {
    font-size: 21px;
    font-family: var(--font-secondary);
    font-weight: 600;
    letter-spacing: 0.64px;
  }
  .image {
    border-radius: 16px 16px 0 0;
    font-size: 21px;
    font-family: var(--font-secondary);
    font-weight: 600;
    letter-spacing: 0.64px;
  }
  .desciption1 {
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: 500;
    line-height: 24px;
  }
  .phoneInput {
    width: 100%;
  }

  .phoneInput input:first-child {
    width: 25%;
  }
}
