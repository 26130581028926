.MediaSlider {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 26px;
  gap: 16px;
  margin-top: 30px;
}
.MediaSlider:first-child {
  margin-left: 26px;
}
.imgSlider {
  width: 165px;
  object-fit: cover;
  object-position: center;
  height: 144px;
}
.player {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 865px) {
  .player {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .MediaSlider:first-child {
    margin-left: 100px;
  }
  .MediaSlider {
    align-items: center;
  }
  .imgSlider {
    width: 275px;
    object-fit: cover;
    object-position: center;
    height: 246px;
  }
}
