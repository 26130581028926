* {
  margin: 0;
  padding: 0;
}

.container-one {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 14.61% 0 29.14%;
}
.bgMe {
  background: var(--secondary-color);
}
html {
  scroll-behavior: smooth;
  user-select: none;
}
pre {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
}
.font-inter {
  font-family: Inter;
}
button:disabled {
  opacity: 80%;
}
.text-largest-clash {
  font-size: 32px;
  font-family: var(--font-secondary);
  font-weight: 600;
  letter-spacing: 0.64px;
}
.text-large-clash {
  font-size: 21px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.text-regular-bold {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 24px;
}
.text-regular-medium {
  font-size: 17px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 24px;
}

.text-small {
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 20px;
}
.text-smallest {
  font-size: 12px;
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 16px;
}
@media screen and (max-width: 500px) {
  .container-one {
    padding: 0 20px;
  }
}
/* talewind md  */
@media (max-width: 768px) {
  .text-largest-clash {
    font-size: 21px;
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.42px;
  }
  .text-regular-bold {
    font-size: 14px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .text-regular-medium {
    font-size: 14px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
.seperator {
  border: 0.5px solid rgba(177, 177, 177, 0.6);
  width: 100%;
  height: 0px;
}
.growtext {
  font-size: 17px;
  transition: font-size 0.2s ease-in-out;
}

.growtext:hover {
  font-size: 20px;
}
.thegridofsix {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}
/* .uk-link-toggle:hover .uk-link, .uk-link:hover, a:hover{
  text-decoration: none;
} */
h1 {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 86px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-family: "Cochin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  text-align: center;
  margin: auto;
  max-width: 1000px;
}
h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.7;
}
h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.bgwyt {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.text_1Line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_Ell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_4Line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.homeWidthController {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.uk-dotnav > .uk-active > * {
  background-color: white;
  border-color: transparent;
}
.uk-dotnav > * > * {
  border: 1px solid grey;
}
.line {
  height: 1px;
  background: #d9d9d9;
  margin-top: 20px;
  opacity: 0.3;
  border-radius: 30px;
}
.leftPadding {
  padding-left: 60px;
}

.rytPadding {
  padding-right: 60px;
}

.App {
  background-color: #fff;
  /* background-image: url("./assests/background3.svg"); */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.helpController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.fifaHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}

.flexio {
  display: flex;
  align-items: center;
}

.help {
  transition: 0.2s;
  bottom: 1.2rem;
  right: 1.5rem;
  z-index: 11;
  position: fixed;
}

.thegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.flexico {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.InfluencermobWidth {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}
.mobWidth {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}

.desktopWidthController {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}

.fullWidthController {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  backdrop-filter: blur(50px);
}

.widthController {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-height: 100vh;
  background-color: black;
}

.noDesk {
  display: none;
}

/* .container{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
} */
.yesToMobile {
  display: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.tint {
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 81.68%, #000000 100%);
  opacity: 0.6;
  bottom: 0;
  height: 250px;
  width: 100%;
  z-index: 555;
}

.satoshi {
  font-family: "Satoshi";
}

.bebas {
  font-family: "Bebas Neue", cursive;
}

.uk-grid-column-small > *,
.uk-grid-small > * {
  padding-left: 0px;
}

.uk-grid-column-small > *,
.uk-grid-small > *:first-child {
  margin-left: 60px;
}

.gapOfSlideForSlide {
  gap: 0px !important;
}

.gapOfSlide {
  gap: 20px !important;
}

/* .uk-slider-items:not(.uk-grid){
  gap: 20px;
} */
input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 0);
  outline-style: none !important;
}
.tempButton {
  padding: 10px 16px;
  border-radius: 54px;
  border: 1px solid #4e04c6;
  background: #4e04c6;
  color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}
.mobileOnly {
  display: none;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  background: white !important;
  backdrop-filter: blur(25px) !important;
  z-index: 1200;
  padding: 50px 20px;
}
.uk-offcanvas-bar {
  background: white !important;
  padding: 50px 20px;
}
.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar::before {
  background: white !important;
  backdrop-filter: blur(25px) !important;
  z-index: 1200;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar::after {
  background: white !important;
  backdrop-filter: blur(25px) !important;
  z-index: 1200;
}
.carousel-dot {
  width: 6px;
  height: 6px;
  background: #868585;
  border-radius: 50%;
  transition: all 0.3s ease-in;
}
.carousel-dot-active {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
}

@media screen and (min-device-width: 481px) and (max-device-width: 864px) {
  .help {
    transition: 0.2s;
    bottom: 1.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }
}

@media screen and (min-device-width: 864px) and (max-device-width: 1024px) {
  .help {
    transition: 0.2s;
    bottom: 8rem;
    right: 7.5rem;
    z-index: 11;
    position: fixed;
  }

  .mobileOnly {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.7;
  }
  .InfluencermobWidth {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    backdrop-filter: blur(50px);
  }
  h1 {
    font-size: 2.5rem;
    line-height: 45px;
  }

  h2 {
    font-size: 1rem;
    padding: 0 24px;
    line-height: 1.2;
  }

  .gapOfSlideForSlide,
  .gapOfSlide {
    gap: 8px !important;
  }

  .uk-grid-column-small > *,
  .uk-grid-small > *:first-child {
    margin-left: 0px;
    padding-left: 16px;
  }

  .uk-grid-column-small > *,
  .uk-grid-small > * {
    padding-left: 0;
    /* margin-left: 0px; */
  }

  .uk-slider-items:not(.uk-grid) {
    gap: 5px;
  }

  .mobileOnly {
    display: block;
  }

  .homeWidthController {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
  }

  .leftPadding {
    padding-left: 16px;
  }

  .rytPadding {
    padding-right: 16px;
  }

  .help {
    transition: 0.2s;
    bottom: 5.2rem;
    right: 1.5rem;
    z-index: 11;
    position: fixed;
  }

  .noMobie {
    display: none;
  }

  .flexico {
    flex-direction: column;
  }

  .noMob {
    display: none;
  }

  .noDesk {
    display: block;
  }

  .yesToMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 20px; */
    padding-top: 0px;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.7;
  }
  .InfluencermobWidth {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    backdrop-filter: blur(50px);
  }
  .flexico {
    flex-direction: column;
  }

  .noDesk {
    display: block;
  }

  .noMobie {
    display: flex;
  }

  .noToTab {
    display: none;
  }
}
