.productCard h2 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-align: left;
  padding: 0;
}
.productCard p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  opacity: 0.6;
}
.productCard button {
  border-radius: 8px;
  padding: 12px 52px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.productCard .button1 {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  color: #161616;
}
.productCard .button2 {
  background: #000;
  color: #fff;
}
.productCard .pointText {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
}
@media (max-width: 640px) {
  .productCard h2 {
    font-size: 24px;
  }

  .productCard button {
    padding: 12px;
    width: 50%;
  }
}
