.campaignCard {
  height: 100%;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 120px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  padding: 20px;
  grid-column: span 4 / span 4;
  position: relative;
}
.campaignCard img {
  width: 70px;
  object-fit: cover;
}
.campaignCard h2 {
  color: #000;
  font-family: "Clash Display", sans-serif;
  text-align: left;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
  margin-left: 0;
  padding: 0;
}
.campaignCard p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 134%; /* 17.42px */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}
.campaignCard span {
  color: #161616;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 17.42px */
}
.campaignCard button {
  border-radius: 8px;
  background: #161616;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 8px 24px;
  width: fit-content;
}
.campaignCard .viewCampaignButton {
  border-radius: 8px;
  background: #f6f2f2;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 184.615% */
  flex-shrink: 0;
}
.promoterCard {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 120px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 189px;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.promoterCard h5 {
  color: #161616;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 17.42px */
}
.emptyPromotor {
  color: #161616;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 134%; /* 17.42px */
  opacity: 0.6;
}
.promotorDp {
  border-radius: 32px;
  border: 1px solid #fff;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.11);
  width: 32px;
  height: 32px;
  object-fit: cover;
  overflow: hidden;
  background: #c9eaf0;
}
.promotorDp:not(:first-child) {
  margin-left: -10px;
}
/* talewind lg  */
@media (max-width: 1024px) {
  .promoterCard {
    display: none;
  }
}

/* talewind md  */
@media (max-width: 768px) {
  .campaigns h1 {
    font-size: 32px;
  }
  .promoterCard {
    display: none;
  }
}
