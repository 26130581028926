.card {
  border-radius: 24px;
  background: #ebebeb;
  flex-shrink: 0;
  width: 280px;
  padding: 23px 16px;
}
.card h3 {
  color: #141551;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 29.76px */
  letter-spacing: -0.48px;
}
.card p {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.52px;
}
.card h4 {
  color: #d301d0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}
.card h5 {
  color: #141551;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.52px;
}
.card img {
  border-radius: 16px;
  background: #d6cbff;
  width: 72px;
  height: 62px;
  flex-shrink: 0;
}
.suitableFor {
  margin-bottom: 22px;
}
.card .suitableFor p {
  color: #1a1a1a;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
}
