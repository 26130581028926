.gridColumn {
  column-count: 2;
  gap: 2px;
  animation: corPage 0.5s ease-out 1 both 0.5s;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
  background-color: black;
}
@keyframes corPage {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.blackUse {
  width: 100%;
  height: 50px;
}
.singleGrid {
  width: 100%;
  margin-top: 2px;
  height: 100%;
}
.singleGrid > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (min-width: 865px) {
  .gridColumn {
    column-count: 4;
    gap: 20px;
    padding-top: 110px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .singleGrid {
    width: 100%;
    padding-bottom: 20px;
    height: 100%;
  }
  .singleGrid > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
