.landing {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
.landing h1 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 69.68px */
  text-transform: capitalize;
  letter-spacing: normal;
  max-width: 75%;
}
.landing h3 {
  color: #161616;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
  max-width: 50%;
  opacity: 1;
}
.landing p {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
  max-width: 30%;
  opacity: 0.6;
}
.landing button {
  padding: 12px 52px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.landing .landingButton {
  margin-top: 23px;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 17.42px */
  border-radius: 12px;
  opacity: 0.6;
  background: #37028d;
  width: 100%;
  padding: 12px;
}
.demo {
  border: 1px solid rgba(55, 2, 141, 0.6);
  background: rgba(55, 2, 141, 0.1);
  color: #161616;
}
.quote {
  background: #37028d;
  color: #fff;
}
.landing p {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
  max-width: 30%;
  opacity: 0.6;
}

.products {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  background: #f0fbff;
  padding: 48px 20px;
}
.products h2 {
  color: #37028d;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 53.6px */
  max-width: 50%;
  padding: 0;
}
.products p {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 21.44px */
  width: 100%;
  max-width: 50%;
}
.products h2 {
  color: #37028d;
  text-align: center;
  font-family: "Clash Display", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 53.6px */
  max-width: 50%;
  padding: 0;
}
.steps {
  max-width: 330px;
  border-radius: 32px;
  padding: 40px 36px;
  border: 8px solid #fff;
  width: fit-content;
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 32.16px */
  text-align: center;
}
.stepsHeading {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 42.88px */
  text-align: left;
  margin: 0;
  padding: 0;
}
.uses {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 6%;
  justify-content: center;
}
.uses h4 {
  color: #161616;
  font-family: "Clash Display", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 28.14px */
  text-align: center;
}
.uses p {
  color: #161616;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}

.videoSection h2 {
  color: #37028d;
  font-family: "Clash Display", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 52.8px */
  text-align: left;
  padding: 0;
  margin: 0;
}
.videoSection p {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 134%; /* 21.44px */
}
/* talewind sm  */
@media (max-width: 640px) {
  .landing {
    align-items: start;
    padding: 30px 16px;
  }
  .landing h1 {
    font-size: 34px;
    max-width: 100%;
  }
  .landing h3 {
    font-size: 24px;
    max-width: 100%;
  }
  .landing p {
    font-size: 13px;
    max-width: 100%;
  }
  .landing button {
    padding: 12px 16px;
    /* width: 50%; */
  }
  .products {
    padding: 20px 20px 0;
  }
  .products h2 {
    font-size: 28px;
    max-width: 100%;
  }
  .products p {
    max-width: 100%;
  }
  .steps {
    font-size: 16px;
    padding: 17px 36px;
    border: 4px solid #fff;
    border-radius: 19px;
  }
  .stepsHeading {
    font-size: 24px;
  }
  .uses {
    display: flex;
    overflow: auto;
    justify-content: start;
    scroll-snap-stop: always;
  }
  .videoSection h2 {
    font-size: 32px;
  }
}
